import React, { useEffect } from 'react'
import { useErrorContext, ErrorSetter } from 'context/error/ErrorContext'
import GoogleButton from './google-button/GoogleButton'
import MicrosoftButton from './microsoft-button/MicrosoftButton'
import styles from './social-login-buttons.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

export interface Props {
  handleGoogleLogin: (tokenId: string) => void
  handleMicrosoftLogin: (tokenId: string, nonce: string) => void
  googleSessionError?: string
  microsoftSessionError?: string
}

export const useSetGlobalError = (
  provider: 'Google' | 'Microsoft',
  globalSetter: ErrorSetter,
  error?: string,
) => {
  useEffect(() => {
    if (error) {
      globalSetter({
        title: `${provider} login failed`,
        description: error,
      })
    } else {
      globalSetter(undefined)
    }
  }, [error, provider, globalSetter])
}

const SocialLoginButtons = ({
  handleGoogleLogin,
  googleSessionError,
  handleMicrosoftLogin,
  microsoftSessionError,
}: Props) => {
  const { t } = useTranslation()
  const { setError } = useErrorContext()

  return (
    <div className={styles['social-login-buttons']}>
      <p className={styles['social-login-buttons__divider']}>
        {t('logIn.socialBtnsDivider')}
      </p>
      <GoogleButton
        setError={setError}
        handleGoogleLogin={handleGoogleLogin}
        googleSessionError={googleSessionError}
      />
      <MicrosoftButton
        setError={setError}
        handleMicrosoftLogin={handleMicrosoftLogin}
        microsoftSessionError={microsoftSessionError}
      />
    </div>
  )
}

export default SocialLoginButtons
