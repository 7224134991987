import {
  ApiLegacySettingsPage,
  ApiSettings,
  LegacySettingsPage,
  TraderSettings,
} from 'yordex-ui-kit'
import { shouldDisplayBasedOnRoles } from 'yordex-ui-kit'
import { arrayToObject } from 'utils/data/dataHelper'

const transformLegacyPages = (
  pages: Array<ApiLegacySettingsPage> = [],
  userRoles: Array<string> = [],
) => {
  let transformedPages: { [key: string]: LegacySettingsPage } = {}
  pages.forEach((item: ApiLegacySettingsPage) => {
    transformedPages[item.page] = {
      ...item,
      shouldDisplayForCurrentUser: shouldDisplayBasedOnRoles(
        item.hiddenForUsers,
        userRoles,
      ),
      fields: arrayToObject(item.fields, 'field'),
    }
  })
  return transformedPages
}

export const transformSettings = (
  settings: ApiSettings,
  userRoles: Array<string> = [],
): TraderSettings => {
  let transformedLegacyUiSettings = { pages: [] }
  const { uiSettings = '', ...traderSettings } = settings
  try {
    const legacyStringUiSettings = uiSettings as string
    transformedLegacyUiSettings = JSON.parse(
      legacyStringUiSettings.replace(/\\n|\\r|\\/, ''),
    )
  } catch (_) {}

  return {
    ...traderSettings,
    angularUISettings: {
      ...transformedLegacyUiSettings,
      pages: transformLegacyPages(transformedLegacyUiSettings.pages, userRoles),
    },
  }
}
