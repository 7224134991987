import { FormikHelpers } from 'formik'
import { useState } from 'react'
import { changePassword } from 'server/fetch/reset-password/fetch'
import { RequestError } from 'utils/fetch/fetchHelper'
import { FormValues } from '../ChooseNewPassword'

type FormHelpers = Pick<
  FormikHelpers<FormValues>,
  'setSubmitting' | 'validateForm'
>

export interface ChangePasswordState {
  errorMessage?: string
  handleChangePassword: (values: FormValues, helpers: FormHelpers) => void
  changedPassword?: string
}

const useResetPassword = (
  email: string,
  passwordResetKey: string,
): ChangePasswordState => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [changedPassword, setChangedPassword] = useState<undefined | string>()

  const handleChangePassword = (
    { newPassword }: FormValues,
    { setSubmitting, validateForm }: FormHelpers,
  ) => {
    setSubmitting(true)

    async function changeUsersPassword() {
      try {
        const errors = await validateForm()
        if (Object.values(errors).every((e) => !e)) {
          await changePassword(email, passwordResetKey, newPassword)
          setSubmitting(false)
          setChangedPassword(newPassword)
        }
      } catch (e) {
        const requestError = e as RequestError
        setErrorMessage(requestError.reason)
        setSubmitting(false)
      }
    }

    changeUsersPassword()
  }

  return { errorMessage, handleChangePassword, changedPassword }
}

export default useResetPassword
