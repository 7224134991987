import React from 'react'
import { getConfig } from 'config'
import { GoogleOAuthProvider } from '@react-oauth/google'

const { googleClientId } = getConfig()

export const withGoogleContext = <TProps extends object>(
  Component: (props: TProps) => JSX.Element | null,
) => (props: TProps) => {
  return (
    <GoogleOAuthProvider clientId={googleClientId!}>
      <Component {...props} />
    </GoogleOAuthProvider>
  )
}
