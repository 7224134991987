import { useFormik } from 'formik'
import { useValidate } from './useValidate'

type ToSModalFormValues = {
  termsOfServiceRead: boolean
  termsOfServiceDeclined: boolean
}

const INITIAL_VALUES: ToSModalFormValues = {
  termsOfServiceRead: false,
  termsOfServiceDeclined: false,
}

export const useToSForm = (
  updateTerms: (accepted: boolean) => Promise<void>,
) => {
  const validate = useValidate()
  const {
    setFieldValue,
    submitForm,
    errors,
    isSubmitting,
    submitCount,
    values,
  } = useFormik<ToSModalFormValues>({
    initialValues: INITIAL_VALUES,
    onSubmit: (values) => {
      return updateTerms(!values.termsOfServiceDeclined)
    },
    validate,
  })

  const isSubmitted = submitCount > 0
  const checkboxError = isSubmitted ? errors.termsOfServiceRead : undefined

  return {
    checkboxError,
    submitForm,
    isSubmitting,
    values,
    setFieldValue,
    isSubmitted,
  }
}
