import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import {
  Footer,
  SettingsError,
  UISettingsError,
  UnexpectedError,
} from 'yordex-ui-kit'
import PrivateNavigation from 'components/private-navigation/PrivateNavigation'
import { useCommonData } from './hooks/useCommonData'
import useFooterItems from './hooks/useFooterItems'
import { useAngularIfReactIsHidden } from 'hooks/useAngularIfReactIsHidden/useAngularIfReactIsHidden'

interface PrivateRouteProps {
  withoutNavigation?: boolean
}

const LoginRedirect: React.FC<{ referer?: string }> = ({ referer }) => (
  <Navigate to={'/login'} state={{ referer }} />
)

const PrivateRoute = ({ withoutNavigation = false }: PrivateRouteProps) => {
  /* eslint-disable prettier/prettier */
  const {
    authToken,
    session,
    settings,
    unauthorized,
    error,
  } = useCommonData()
  const isReactHidden = useAngularIfReactIsHidden()
  /* eslint-enable prettier/prettier */
  const footerItems = useFooterItems()
  const location = useLocation()

  if (error) {
    if (error instanceof UISettingsError) {
      return <SettingsError />
    } else {
      return <UnexpectedError />
    }
  }

  if (!authToken || unauthorized) {
    return <LoginRedirect referer={location && location.pathname} />
  }

  if (!session || !settings || isReactHidden) return null

  return (
    <>
      {!withoutNavigation && <PrivateNavigation />}
      <Outlet />
      {!withoutNavigation && <Footer items={footerItems} />}
    </>
  )
}

export default PrivateRoute
