import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import enTranslation from '../public/locales/en/translation.json'
import esTranslation from '../public/locales/es/translation.json'
import frTranslation from '../public/locales/fr/translation.json'
import nlTranslation from '../public/locales/nl/translation.json'
import plTranslation from '../public/locales/pl/translation.json'

const initTranslations = () => {
  const instance = i18n.createInstance()
  instance
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en: { translation: enTranslation },
        es: { translation: esTranslation },
        fr: { translation: frTranslation },
        nl: { translation: nlTranslation },
        pl: { translation: plTranslation },
      },
    })

  return instance
}

export default initTranslations
