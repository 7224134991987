import React from 'react'
import { Alert, Modal, useModal } from 'yordex-ui-kit'
import { useShouldDisplayToSModal } from './hooks/useShouldDisplayToSModal'
import { useSubmitToSModal } from './hooks/useSubmitToSModal'
import ToSAcceptanceModalContent from './components/ToSAcceptanceModalContent'
import TosDeclineConfirmationModalContent from './components/TosDeclineConfirmationModalContent'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { useToSForm } from './hooks/useToSForm'
import styles from './tos-modal.module.scss'
import { useModalActions } from './hooks/useModalActions'

export type ToSModalFormValues = {
  termsOfServiceRead: boolean
  termsOfServiceDeclined: boolean
}

const ToSModal = () => {
  const { t } = useTranslation()
  const { close, isOpen, open } = useModal()
  useShouldDisplayToSModal(open)
  const { updateTerms, error } = useSubmitToSModal(close)
  const {
    checkboxError,
    isSubmitted,
    isSubmitting,
    setFieldValue,
    submitForm,
    values,
  } = useToSForm(updateTerms)

  const modalActions = useModalActions(
    values.termsOfServiceDeclined,
    setFieldValue,
    submitForm,
  )

  return (
    <Modal
      className={styles['tos-modal']}
      title={t('tosModal.title')}
      isOpen={isOpen}
      closable={false}
      disabled={isSubmitting}
      {...modalActions}
    >
      {error && (
        <Alert
          className={styles['tos-modal__alert']}
          title={t('tosModal.error')}
          type="error"
        >
          {error}
        </Alert>
      )}
      {values.termsOfServiceDeclined ? (
        <TosDeclineConfirmationModalContent />
      ) : (
        <ToSAcceptanceModalContent
          isSubmitted={isSubmitted}
          isSubmitting={isSubmitting}
          onCheckboxChange={(e) => {
            setFieldValue('termsOfServiceRead', e.target.checked)
          }}
          termsOfServiceRead={values.termsOfServiceRead}
          checkboxError={checkboxError}
        />
      )}
    </Modal>
  )
}

export default ToSModal
