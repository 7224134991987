import React, { useMemo, useState } from 'react'
import { FormikHelpers } from 'formik'
import { ApiSession, LinkButton } from 'yordex-ui-kit'
import styles from './login-otp.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import PublicLayout from 'components/public-layout/PublicLayout'
import { Formik } from 'formik'
import { object } from 'yup'
import { tokenValidationSchema } from 'utils/validation/user'
import { LoginSms } from '../login-sms/LoginSms'
import { googleLogin, login, microsoftLogin } from 'server/fetch/login/fetch'

export interface LoginOtpProps {
  session: ApiSession
  otploginErrorMessage?: string
  handleOtpLogin: (
    values: LoginOtpFormValues,
    helpers: Pick<
      FormikHelpers<LoginOtpFormValues>,
      'setSubmitting' | 'validateForm'
    >,
  ) => void
  username: string
  passcode: string
  googleTokenId?: string
  microsoftTokenId?: string
  uniqueNonce?: string
}

export interface LoginOtpFormValues {
  token: string
}

/* eslint-disable prettier/prettier */
export const validateToken = object().shape({
  token: tokenValidationSchema
})
/* eslint-enable prettier/prettier */

export const LoginOtp = ({
  session,
  otploginErrorMessage,
  handleOtpLogin,
  username,
  passcode,
  googleTokenId,
  microsoftTokenId,
  uniqueNonce,
}: LoginOtpProps) => {
  const { t } = useTranslation()
  const [wasNewTokenRequested, setWasNewTokenRequested] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleRequestAnotherToken = async () => {
    if (googleTokenId) {
      await googleLogin(googleTokenId)
    } else if (microsoftTokenId && uniqueNonce) {
      await microsoftLogin(microsoftTokenId, uniqueNonce)
    } else {
      await login(username, passcode)
    }
    setWasNewTokenRequested(true)
  }

  const getSubTitle = useMemo(() => {
    if (session.otpInfo!.otpStatus === 'SMS_SENT') {
      let lastDigits = ''
      if (session.mobileNumber) {
        lastDigits = session.mobileNumber.substr(
          session.mobileNumber.length - 3,
        )
      }

      return t('logIn.tokenSentBySms', { lastDigits })
    }
    if (session.otpInfo!.otpStatus === 'EMAIL_SENT') {
      return t('logIn.tokenSentByEmail')
    }
  }, [session, t])

  return (
    <PublicLayout
      title={t('logIn.enterToken')}
      subtitle={
        <>
          {getSubTitle}
          <span className={styles['login-otp__request-token-info']}>
            {t('logIn.youCan')}
          </span>
          <LinkButton
            className={styles['login-otp__request-token-link']}
            onClick={() => {
              handleRequestAnotherToken()
            }}
          >
            {t('logIn.requestAnotherToken')}
          </LinkButton>
        </>
      }
    >
      <Formik<LoginOtpFormValues>
        initialValues={{ token: '' }}
        onSubmit={handleOtpLogin}
        validationSchema={validateToken}
      >
        {({ isSubmitting }) => (
          <LoginSms
            session={session}
            submitting={isSubmitting}
            submitted={submitted}
            newTokenInfo={wasNewTokenRequested}
            errorMessage={otploginErrorMessage}
            onSubmit={() => setSubmitted(true)}
          />
        )}
      </Formik>
    </PublicLayout>
  )
}

export default LoginOtp
