import { post } from 'utils/fetch/fetchHelper'

export const resetPassword = (email: string) =>
  post(`/users/${email}/passwordResetKey`, {})

export const changePassword = (
  email: string,
  passwordResetKey: string,
  password: string,
) =>
  post(`/users/${email}/password/reset`, {
    password,
    passwordResetKey,
  })
