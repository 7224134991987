import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { useCallback } from 'react'
import { ToSModalFormValues } from '../ToSModal'

export const useValidate = () => {
  const { t } = useTranslation()

  return useCallback(
    (values: ToSModalFormValues) => {
      if (!values.termsOfServiceRead && !values.termsOfServiceDeclined) {
        return { termsOfServiceRead: t('tosModal.requiredCheckbox') }
      }
    },
    [t],
  )
}
