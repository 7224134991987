import { post, patch } from 'utils/fetch/fetchHelper'
import { ApiSession } from 'yordex-ui-kit'

interface UserCredentials {
  username: string
  password: string
}

interface OTP {
  otp: string
}

interface MobilePhoneNumber {
  mobileNumber: string
  mobileNumberCountryCode: string
  phoneCountryCode: string
}

export const login = (userName: string, password: string) =>
  post<UserCredentials, ApiSession>('/sessions', {
    username: userName,
    password,
  })

export const otpLogin = (otp: string) =>
  post<OTP, ApiSession>('/sessions/otp', {
    otp,
  })

export const updateMobileNumber = (
  mobileNumber: string,
  mobileNumberCountryCode: string,
  phoneCountryCode: string,
  userId: string,
) =>
  patch<MobilePhoneNumber, void>(`/users/${userId}/otp/mobile`, {
    mobileNumber,
    mobileNumberCountryCode,
    phoneCountryCode,
  })

interface GoogleCredentials {
  code: string
}

export const googleLogin = (code: string) =>
  post<GoogleCredentials, ApiSession>('/sessions/google/code', {
    code,
  })

interface MicrosoftCredentials {
  idToken: string
  nonce: string
}

export const microsoftLogin = (idToken: string, nonce: string) =>
  post<MicrosoftCredentials, ApiSession>('/sessions/microsoft', {
    idToken,
    nonce,
  })
