import { useSession } from 'yordex-ui-kit'
import { microsoftLogin } from 'server/fetch/login/fetch'
import { RequestError } from 'utils/fetch/fetchHelper'
import { useAuth } from 'context/auth/AuthContext'
import { useState } from 'react'
import { setMetaCookie } from 'utils/cookies/cookiesHelper'

export interface MicrosoftLoginState {
  handleMicrosoftLogin: (tokenId: string, nonce: string) => void
  microsoftSessionError?: string
  microsoftTokenId?: string
  uniqueNonce?: string
}

export const useMicrosoftLogin = (
  setLoggedIn: (isLoggedIn: boolean) => void,
): MicrosoftLoginState => {
  const { setAuthToken } = useAuth()
  const { setSession } = useSession()
  const [microsoftSessionError, setMicrosoftSessionError] = useState<
    string | undefined
  >()
  const [microsoftTokenId, setMicrosoftTokenId] = useState<string | undefined>()
  const [uniqueNonce, setNonce] = useState<string | undefined>()

  const handleMicrosoftLogin = (tokenId: string, nonce: string) => {
    async function loginUserWithMicrosoft() {
      try {
        setMicrosoftSessionError(undefined)
        const session = await microsoftLogin(tokenId, nonce)
        setSession(session)
        setAuthToken(session.token)
        setMetaCookie(session)
        setLoggedIn(true)
        setMicrosoftTokenId(tokenId)
        setNonce(nonce)
      } catch (e) {
        const requestError = e as RequestError
        setMicrosoftSessionError(requestError.reason)
      }
    }

    loginUserWithMicrosoft()
  }

  return {
    handleMicrosoftLogin,
    microsoftSessionError,
    microsoftTokenId,
    uniqueNonce,
  }
}
