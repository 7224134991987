import { getConfig } from 'config'
import { useEffect } from 'react'
import { fetchUser } from 'server/fetch/user/termsOfService'
import { fetchYordexPay } from 'server/fetch/yordexpay/fetch'
import { useSession } from 'yordex-ui-kit'

export const useShouldDisplayToSModal = (openModal: () => void) => {
  const { tosEnabled } = getConfig()
  const { session } = useSession()

  useEffect(() => {
    const checkIfShouldDisplayToSModal = async () => {
      if (session) {
        const yordexPay = await fetchYordexPay()
        const hasUSBankAccount = !!yordexPay.accounts?.some(
          (account) =>
            account.status === 'ACTIVATED' &&
            account.currency === 'USD' &&
            ['STRIPE', 'HIGHNOTE'].includes(account.provider),
        )

        const user = await fetchUser(session.userId)
        const answeredTos = typeof user.termsOfService?.accepted === 'boolean'

        if (tosEnabled && !answeredTos && hasUSBankAccount) {
          openModal()
        }
      }
    }

    if (session?.tokenType === 'USER_TOKEN') {
      checkIfShouldDisplayToSModal()
    }
  }, [session, openModal, tosEnabled])
}
