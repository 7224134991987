import { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'context/auth/AuthContext'
import { useLogin } from 'templates/login/hooks/useLogin'

export const useRedirectIfAlreadyAuthorized = () => {
  const { authToken } = useAuth()
  const { session } = useLogin()
  const navigate = useNavigate()

  useLayoutEffect(() => {
    if (authToken && session && session.traderId) {
      navigate('/')
    }
    // Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
