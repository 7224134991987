import React from 'react'
import styles from './reset-password-form.module.scss'
import { useField, Form } from 'formik'
import { PrimaryButton, Input, FormField, Alert } from 'yordex-ui-kit'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

interface Props {
  submitting: boolean
  submitted: boolean
  onSubmit: () => void
  errorMessage?: string
}

const ResetPasswordForm = ({
  submitting,
  submitted,
  onSubmit,
  errorMessage,
}: Props) => {
  const { t } = useTranslation()
  const [emailField, emailMeta] = useField('email')

  return (
    <Form className={styles['reset-password-form']} noValidate>
      {errorMessage && (
        <Alert className={styles['login-form__error-message']} type="error">
          {errorMessage}
        </Alert>
      )}
      <FormField
        className={styles['reset-password-form__input']}
        id="email"
        label={{ name: t('forgotPassword.form.email') }}
        errorMessage={emailMeta.error}
        submitting={submitting}
        submitted={submitted}
        required={true}
      >
        <Input
          id="email"
          {...emailField}
          large
          required
          type="text"
          disabled={submitting}
          error={submitted && !!emailMeta.error}
        />
      </FormField>
      <PrimaryButton
        type="submit"
        large
        disabled={submitting}
        className={styles['reset-password-form__button']}
        onClick={onSubmit}
      >
        {t('forgotPassword.form.submitBtn')}
      </PrimaryButton>
    </Form>
  )
}

export default ResetPasswordForm
