import Cookies from 'js-cookie'
import { ApiSession } from 'yordex-ui-kit'
import { getSubdomain } from '../navigation/navigationHelper'

export const getAuthTokenCookie = () => Cookies.get('devyordexcom')

export const setAuthTokenCookie = (value?: string) =>
  value
    ? Cookies.set('devyordexcom', value, { domain: getSubdomain() })
    : Cookies.remove('devyordexcom')

type MetaCookie = {
  userId: string
  userFullName: string
  userType: string
  userRoles?: Array<string>
  traderId: string
  companyTradingName?: string
  tokenType: string
}

export const getMetaCookie = () => Cookies.get('devyordexcommeta')

export const setMetaCookie = (session?: ApiSession) => {
  if (session) {
    const cookie: MetaCookie = {
      userId: session.userId,
      userFullName: `${session.firstName} ${session.lastName}`,
      userType: session.userType,
      userRoles: session.roles,
      traderId: session.traderId,
      companyTradingName: session.companyTradingName,
      tokenType: session.tokenType,
    }

    return Cookies.set('devyordexcommeta', JSON.stringify(cookie))
  }

  return Cookies.remove('devyordexcommeta')
}
