import DelegatesModal from 'components/delegates-modal/DelegatesModal'
import { useAuth } from 'context/auth/AuthContext'
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchSession } from 'server/fetch/session/fetch'
import classNames from 'classnames'
import styles from './trader-select.module.scss'
import { ReactComponent as ChevronDown } from 'yordex-ui-kit/assets/icons/chevron-down.svg'
import {
  ApiSession,
  Paper,
  SearchInput,
  TraderToken,
  useSession,
  Option,
  Button,
  useHandleClickOutside,
} from 'yordex-ui-kit'

export interface Props {
  traderTokens: Array<TraderToken>
  session: ApiSession
}

const goHomeAndRefresh = () => {
  window.location.href = '/'
}

const TraderSelect = ({ traderTokens, session }: Props) => {
  const { setAuthToken, authToken } = useAuth()
  const { setSession } = useSession()
  const location = useLocation()
  const [isChanging, setIsChanging] = useState(false)
  const [delegatesModalOpen, setDelegatesModalOpen] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [traderFilter, setTraderFilter] = useState<string>('')
  const [selectedTrader, setSelectedTrader] = useState(
    session.companyTradingName,
  )
  const buttonRef = useRef<HTMLButtonElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const dataTestId = 'traderSelector'
  const id = 'trader-token'

  useEffect(() => {
    if (session) {
      const hasCurrentTrader = !!traderTokens.find(
        (trader) => trader.token === session.token,
      )
      if (!hasCurrentTrader) {
        traderTokens.push({
          companyTradingName: session.companyTradingName!,
          token: session.token,
        })
      }
    }
  }, [traderTokens, session])

  const onChangeTrader = async (value: TraderToken) => {
    setIsOpen(false)
    setSelectedTrader(value.companyTradingName)
    const token = value.token
    setAuthToken(token)

    setIsChanging(true)
    const session = await fetchSession()
    setSession(session)

    if (session.delegateTokens) {
      setDelegatesModalOpen(true)
    } else {
      goHomeAndRefresh()
    }
  }

  const handleClick = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true)
    }
  }, [isOpen])

  const onClose = () => {
    setIsOpen(false)
    setTraderFilter('')
  }

  useHandleClickOutside(isOpen, onClose, [listRef, buttonRef])

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  const handleChange = (value: TraderToken) => {
    onChangeTrader(value)
    setIsOpen(false)
  }

  const renderButton = () => {
    return (
      <Button
        className={classNames(
          styles['trader-selector__button'],
          isOpen && styles['trader-selector__button--focused'],
        )}
        onClick={handleClick}
        disabled={isChanging}
        data-testid={`${dataTestId}.button`}
      >
        <span className={classNames(styles['trader-selector__button-text'])}>
          {selectedTrader}
        </span>
        <ChevronDown
          className={classNames(styles['trader-selector__button-icon'])}
        />
      </Button>
    )
  }

  const filteredOptions = useMemo(
    () =>
      traderTokens.filter((traderToken) =>
        `${traderToken.companyTradingName}`
          .toLocaleLowerCase()
          .includes(traderFilter.toLocaleLowerCase()),
      ),
    [traderTokens, traderFilter],
  )

  const renderDropdown = () => {
    return (
      <Paper
        data-testid={`${dataTestId}.container`}
        className={styles['trader-selector__container']}
        ref={listRef}
      >
        <SearchInput
          className={styles['trader-selector__input']}
          data-testid={`${dataTestId}.search`}
          id={`${id}.search`}
          name="trader-selector-search"
          placeholder="Search…"
          ref={inputRef}
          onChange={(event) => setTraderFilter(event.currentTarget.value)}
          value={traderFilter}
        />
        <ul className={styles['trader-selector__list']}>
          {filteredOptions.map((option) => {
            return (
              <Option
                key={option.token}
                selected={option.token === authToken}
                className={styles['trader-selector__option']}
                onClick={() => {
                  handleChange(option)
                }}
              >
                {option.companyTradingName}
              </Option>
            )
          })}
        </ul>
      </Paper>
    )
  }

  return (
    <>
      <div
        className={classNames(
          styles['trader-selector'],
          isOpen && styles['trader-selector--open'],
        )}
      >
        {renderButton()}
        {isOpen && renderDropdown()}
      </div>
      <DelegatesModal
        session={session}
        close={goHomeAndRefresh}
        isOpen={delegatesModalOpen}
        referer={location.pathname}
      />
    </>
  )
}

export default TraderSelect
