import { getConfig } from 'config'
import { useMemo } from 'react'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { getSubdomain } from 'utils/navigation/navigationHelper'
import { Category, useCorrespondingAngularRoute } from 'yordex-ui-kit'

const { fallbackRouteHost } = getConfig()
const OLD_APP_HOST = fallbackRouteHost || `https://${getSubdomain()}`

const useCategories = (): Array<Category> => {
  const { t } = useTranslation()
  const angularPathname = useCorrespondingAngularRoute()

  return useMemo<Array<Category>>(
    () => [
      {
        id: 'spend',
        pageId: ['ORDER', 'EXPENSES'], // pageId should match a valid API's page id to show/hide the section based on user's uiSettings
        title: t('navigation.spend'),
        href: '/spend',
        level: 'primary',
      },
      {
        id: 'tasks',
        pageId: 'TASKS',
        title: t('navigation.tasks'),
        href: '/tasks',
        level: 'primary',
      },
      {
        id: 'suppliers',
        pageId: 'CONTACT',
        title: t('navigation.suppliers'),
        href: '/suppliers',
        level: 'primary',
      },
      {
        id: 'finance',
        title: t('navigation.finance'),
        items: [
          {
            id: 'statement',
            pageId: 'MASSPAYMENTS',
            title: t('navigation.statement'),
            href: '/finance/statement',
          },
          {
            id: 'spend',
            pageId: 'SPENDREPORTS',
            title: t('navigation.spendReports'),
            href: '/finance/spend-reports',
          },
          {
            id: 'paymentRun',
            pageId: 'PAYOUTS',
            title: t('navigation.paymentRun'),
            href: '/finance/payment-run',
            usesNewSettings: true,
          },
        ],
        level: 'primary',
      },
      {
        id: 'cards',
        pageId: 'CARDS',
        title: t('navigation.cards'),
        href: '/cards',
        level: 'primary',
      },
      {
        id: 'invoices',
        pageId: 'INVOICES',
        icon: 'bell',
        href: '/invoices',
        level: 'secondary',
        usesNewSettings: true,
      },
      {
        id: 'email',
        title: t('navigation.email'),
        type: 'button',
        level: 'secondary',
        hideForMobileScreens: true,
        CustomComponent: null,
        pageId: 'EMAIL',
        usesNewSettings: true,
      },
      {
        id: 'old-app',
        icon: 'externalLink',
        href: `${OLD_APP_HOST}${angularPathname}`,
        level: 'secondary',
        external: true,
        pageId: 'OLD-APP',
        usesNewSettings: true,
      },
      {
        id: 'help',
        icon: 'help',
        href: 'https://help.yordex.com',
        level: 'secondary',
        external: true,
        target: '_blank',
      },
      {
        id: 'settings',
        icon: 'cog',
        level: 'secondary',
        items: [
          {
            id: 'profile',
            pageId: 'COMPANYPROFILE',
            title: t('navigation.profile'),
            href: '/settings/profile',
          },
          {
            id: 'terms',
            pageId: 'PAYMENTTERMS',
            title: t('navigation.terms'),
            href: '/settings/terms',
          },
          {
            id: 'services',
            pageId: 'SERVICES',
            title: t('navigation.services'),
            href: '/settings/services',
          },
          {
            id: 'user-management',
            pageId: 'USERMANAGEMENT',
            title: t('navigation.userManagement'),
            href: '/settings/users',
          },
          {
            id: 'delegate',
            pageId: 'DELEGATE',
            title: t('navigation.delegate'),
            href: '/settings/delegates',
            usesNewSettings: true,
          },
          {
            id: 'cost-allocations',
            pageId: 'BUDGETSETTINGS',
            title: t('navigation.costAllocations'),
            href: '/settings/costallocations',
          },
          {
            id: 'account',
            pageId: 'ACCOUNT',
            title: t('navigation.account'),
            href: '/settings/account',
          },
          {
            id: 'api-keys',
            pageId: 'APIKEYS',
            title: t('navigation.apiKeys'),
            href: '/settings/api-keys',
            usesNewSettings: true,
          },
          {
            id: 'logout',
            title: t('navigation.logout'),
            href: '/logout',
          },
        ],
      },
      {
        id: 'language',
        type: 'dropdown',
        level: 'secondary',
        hideForMobileScreens: true,
      },
      {
        id: 'my-account',
        icon: 'user',
        hideForMobileScreens: true,
        level: 'secondary',
        items: [
          {
            id: 'account',
            pageId: 'ACCOUNT',
            title: t('navigation.account'),
            href: '/settings/account',
          },
          {
            id: 'logout',
            title: t('navigation.logout'),
            href: '/logout',
          },
        ],
      },
      {
        id: 'tablet-menu',
        title: t('navigation.menu'),
        type: 'button',
        level: 'tablet',
        items: [
          {
            id: 'tablet-spend',
            pageId: ['ORDER', 'EXPENSESLIST'],
            title: t('navigation.spend'),
            href: '/spend',
          },
          {
            id: 'tablet-tasks',
            pageId: 'TASKS',
            title: t('navigation.tasks'),
            href: '/tasks',
          },
          {
            id: 'tablet-reports',
            pageId: 'MASSPAYMENTS',
            title: t('navigation.statement'),
            href: '/finance/statement',
          },
          {
            id: 'tablet-suppliers',
            pageId: 'CONTACT',
            title: t('navigation.suppliers'),
            href: '/suppliers',
          },
          {
            id: 'tablet-cards',
            pageId: 'CARDS',
            title: t('navigation.cards'),
            href: '/cards',
          },
          {
            id: 'tablet-payouts',
            pageId: 'PAYOUTS',
            title: t('navigation.paymentRun'),
            href: '/finance/payment-run',
            usesNewSettings: true,
          },
        ],
      },
      {
        id: 'mobile-menu',
        title: t('navigation.menu'),
        type: 'button',
        level: 'mobile',
        items: [
          {
            id: 'mobile-spend',
            pageId: ['ORDER', 'EXPENSESLIST'],
            title: t('navigation.spend'),
            href: '/spend',
          },
          {
            id: 'mobile-tasks',
            pageId: 'TASKS',
            title: t('navigation.tasks'),
            href: '/tasks',
          },
          {
            id: 'mobile-reports',
            pageId: 'MASSPAYMENTS',
            title: t('navigation.statement'),
            href: '/finance/statement',
          },
          {
            id: 'mobile-suppliers',
            pageId: 'CONTACT',
            title: t('navigation.suppliers'),
            href: '/suppliers',
          },
          {
            id: 'mobile-cards',
            pageId: 'CARDS',
            title: t('navigation.cards'),
            href: '/cards',
          },
          {
            id: 'mobile-payouts',
            pageId: 'PAYOUTS',
            title: t('navigation.paymentRun'),
            href: '/finance/payment-run',
            usesNewSettings: true,
          },
          {
            id: 'mobile-profile',
            pageId: 'COMPANYPROFILE',
            title: t('navigation.profile'),
            href: '/settings/profile',
          },
          {
            id: 'mobile-terms',
            pageId: 'TERMS',
            title: t('navigation.terms'),
            href: '/settings/terms',
          },
          {
            id: 'mobile-services',
            pageId: 'SERVICES',
            title: t('navigation.services'),
            href: '/settings/services',
          },
          {
            id: 'mobile-users',
            pageId: 'USERMANAGEMENT',
            title: t('navigation.userManagement'),
            href: '/settings/users',
          },
          {
            id: 'mobile-help',
            title: t('navigation.help'),
            href: '/help',
          },
          {
            id: 'mobile-delegate',
            pageId: 'DELEGATE',
            title: t('navigation.delegate'),
            href: '/settings/delegates',
          },
          {
            id: 'mobile-cost-allocations',
            pageId: 'BUDGETSETTINGS',
            title: t('navigation.costAllocations'),
            href: '/settings/costallocations',
          },
          {
            id: 'mobile-account',
            pageId: 'ACCOUNT',
            title: t('navigation.account'),
            href: '/settings/account',
          },
          {
            id: 'mobile-api-keys',
            pageId: 'APIKEYS',
            title: t('navigation.apiKeys'),
            href: '/settings/api-keys',
            usesNewSettings: true,
          },
          {
            id: 'mobile-old-app',
            title: t('navigation.oldApp'),
            href: `${OLD_APP_HOST}${angularPathname}`,
            external: true,
          },
          {
            id: 'mobile-logout',
            title: t('navigation.logout'),
            href: '/logout',
          },
        ],
      },
    ],
    [t, angularPathname],
  )
}

export default useCategories
