import React, { useCallback } from 'react'
import { ApiError, Button } from 'yordex-ui-kit'
import { ErrorSetter } from 'context/error/ErrorContext'
import { useSetGlobalError } from '../SocialLoginButtons'
import GoogleLogo from 'assets/logo-google.svg'
import styles from '../social-login-buttons.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { CodeResponse, useGoogleLogin } from '@react-oauth/google'
import { withGoogleContext } from 'context/google/withGoogleContext'

export interface GoogleButtonProps {
  handleGoogleLogin: (tokenId: string) => void
  googleSessionError?: string
  setError: ErrorSetter
}

const GoogleButton = ({
  handleGoogleLogin,
  googleSessionError,
  setError,
}: GoogleButtonProps) => {
  const { t } = useTranslation()
  useSetGlobalError('Google', setError, googleSessionError)

  const onSuccess = useCallback(
    async (tokenResponse: CodeResponse) => {
      try {
        setError(undefined)
        const { code } = tokenResponse

        handleGoogleLogin(code)
      } catch (e) {
        setError({
          title: t('logIn.google.errorTitle'),
          description:
            (e as ApiError).reason || t('logIn.google.errorDescription'),
        })
      }
    },
    [handleGoogleLogin, setError, t],
  )

  const onError = useCallback(
    (errorResponse: { error_description?: string }) => {
      setError({
        title: t('logIn.google.errorTitle'),
        description:
          errorResponse.error_description || t('logIn.google.errorDescription'),
      })
    },
    [setError, t],
  )

  const onNonOAuthError = useCallback(
    (nonAuthError: { type: string }) => {
      switch (nonAuthError.type) {
        case 'popup_closed': {
          // we don't do anything when user just closes the popup
          return
        }
        case 'popup_failed_to_open': {
          setError({
            title: t('logIn.google.errorTitle'),
            description: t('logIn.google.popupFailedError'),
          })
          return
        }
        case 'unknown': {
          setError({
            title: t('logIn.google.errorTitle'),
            description: t('logIn.google.errorDescription'),
          })
          return
        }
      }
    },
    [setError, t],
  )

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess,
    onError,
    onNonOAuthError,
  })

  return (
    <div className={styles['social-login-buttons__google-container']}>
      <Button
        onClick={() => {
          setError(undefined)
          login()
        }}
        large
        className={styles['social-login-buttons__button']}
      >
        <img
          src={GoogleLogo}
          className={styles['social-login-buttons__logo']}
        />{' '}
        {t('logIn.google.button')}
      </Button>
    </div>
  )
}

export default withGoogleContext(GoogleButton)
