import { PASSWORD_REGEX } from 'yordex-ui-kit'
import { string } from 'yup'

export const emailValidationSchema = string()
  .email('Invalid email')
  .required('Email is required')

export const passwordValidationSchema = string()
  .matches(
    PASSWORD_REGEX,
    'Enter 8+ characters with at least one number and one uppercase',
  )
  .required('Password is required')

export const tokenValidationSchema = string().required('Token is required')

const NUMBER_REGEX = /^\d+$/

export const mobileNumberValidationSchema = string()
  .matches(
    NUMBER_REGEX,
    'Mobile Number can only have digits and length between 4 and 13 numbers.',
  )
  .min(
    4,
    'Mobile Number can only have digits and length between 4 and 13 numbers.',
  )
  .max(
    13,
    'Mobile Number can only have digits and length between 4 and 13 numbers.',
  )
  .required('Mobile number is required')

export const mobileNumberCountryCodeValidationSchema = string().required(
  'Country code is required',
)
