import {
  Category,
  LegacySettingsPage,
  TraderSettings,
  UISettings,
} from 'yordex-ui-kit'
import { getConfig } from 'config'
import { matchPath } from 'react-router-dom'

const shouldDisplayPage = (page: LegacySettingsPage) =>
  page ? !!page.shouldDisplayForCurrentUser : true

const shouldShowCategory = (
  category: Category,
  pages: {
    [key: string]: LegacySettingsPage
  },
  settings: UISettings,
): boolean => {
  if (category.usesNewSettings) {
    const pageSetting = settings.pages.find((p) => p.id === category.pageId)
    return pageSetting ? pageSetting.visible : false
  }

  return Array.isArray(category.pageId)
    ? category.pageId.some((p) => shouldDisplayPage(pages[p]))
    : shouldDisplayPage(pages[category.pageId!])
}

export const getVisibleCategoriesForUserSettings = (
  settings: TraderSettings | undefined,
  uiSettings: UISettings | undefined,
  categories: Category[],
) => {
  const { angularUISettings = {} } = settings || {}
  const { pages } = angularUISettings
  if (!pages || !uiSettings) {
    return categories
  }

  return categories
    .filter((category: Category) => {
      const { items = [] } = category
      const shouldShow = shouldShowCategory(category, pages, uiSettings)
      if (shouldShow && items.length) {
        const hasVisibleItems = items.some((subcategory: Category) =>
          shouldShowCategory(subcategory, pages, uiSettings),
        )
        return hasVisibleItems
      }
      return shouldShow
    })
    .map((category: Category) => {
      const { items = [] } = category
      if (items.length) {
        const visibleItems = items.filter((subcategory: Category) =>
          shouldShowCategory(subcategory, pages, uiSettings),
        )
        return {
          ...category,
          items: visibleItems,
        }
      } else {
        return category
      }
    })
}

export const getSubdomain = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_appName, ...subDomainParts] = location.hostname.split('.')

  // localhost
  if (subDomainParts.length === 0) {
    return location.hostname
  }

  const angularAppDomain = subDomainParts.join('.')
  return angularAppDomain
}

export const getAngularAppUrl = () => {
  const { fallbackRouteHost } = getConfig()
  const angularURL = fallbackRouteHost || `https://${getSubdomain()}`

  return angularURL
}

const hrefToParentPattern = (href: string) => {
  // react router uses /* to match against children
  // we want to change /cards href (main menu)
  // to /cards/* (all cards subpages) to determine the active menu item
  return `${href}/*`
}

export const markActiveCategory = (
  visibleCategories: Array<Category>,
  pathname: string,
): Array<Category> => {
  return visibleCategories.map((category) => {
    // dropdown
    if (category.items) {
      const activeItem = category.items.find((item) => {
        return !!matchPath({ path: hrefToParentPattern(item.href!) }, pathname)
      })

      if (activeItem) {
        return {
          ...category,
          active: true,
          items: category.items.map((item) =>
            item === activeItem ? { ...item, active: true } : item,
          ),
        }
      } else {
        return { ...category, active: false }
      }
    }
    // normal link
    const isActive = category.href
      ? !!matchPath({ path: hrefToParentPattern(category.href) }, pathname)
      : false

    return {
      ...category,
      active: isActive,
    }
  })
}
