import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { ApiSession, useSession } from 'yordex-ui-kit'
import { login, otpLogin } from 'server/fetch/login/fetch'
import { setAuthTokenCookie, setMetaCookie } from 'utils/cookies/cookiesHelper'
import { RequestError } from 'utils/fetch/fetchHelper'
import { FormValues } from '../Login'
import { LoginOtpFormValues } from '../login-otp/LoginOtp'
import { useAuth } from 'context/auth/AuthContext'
import { useGoogleLogin } from './useGoogleLogin'
import { useMicrosoftLogin } from './useMicrosoftLogin'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

export interface LoginState {
  isLoggedIn: boolean
  handleLogin: (
    values: FormValues,
    helpers: Pick<FormikHelpers<FormValues>, 'setSubmitting' | 'validateForm'>,
  ) => void
  handleOtpLogin: (
    values: LoginOtpFormValues,
    helpers: Pick<
      FormikHelpers<LoginOtpFormValues>,
      'setSubmitting' | 'validateForm'
    >,
  ) => void
  handleGoogleLogin: (tokenId: string) => void
  handleMicrosoftLogin: (tokenId: string, nonce: string) => void
  loginErrorMessage?: string
  otploginErrorMessage?: string
  mobileNumberErrorMessage?: string
  session?: ApiSession
  authToken?: string
  googleSessionError?: string
  googleTokenId?: string
  microsoftSessionError?: string
  microsoftTokenId?: string
  uniqueNonce?: string
}

export const useLogin = (): LoginState => {
  const { t } = useTranslation()
  const { authToken, setAuthToken } = useAuth()
  const [isLoggedIn, setLoggedIn] = useState(!!authToken)
  const { session, setSession } = useSession()
  const [loginErrorMessage, setLoginErrorMessage] = useState<
    string | undefined
  >()
  const [otploginErrorMessage, setOtpLoginErrorMessage] = useState<
    string | undefined
  >()

  const handleLogin = (
    { email, password }: FormValues,
    {
      setSubmitting,
      validateForm,
    }: Pick<FormikHelpers<FormValues>, 'setSubmitting' | 'validateForm'>,
  ) => {
    setSubmitting(true)

    async function loginUser() {
      try {
        const errors = await validateForm()
        if (Object.values(errors).every((e) => !e)) {
          const session = await login(email, password)
          setSession(session)
          setAuthTokenCookie(session.token)
          setAuthToken(session.token)
          setMetaCookie(session)
          setLoggedIn(true)
        }
      } catch (e) {
        const requestError = e as RequestError
        setLoginErrorMessage(requestError.reason)
      } finally {
        setSubmitting(false)
      }
    }

    loginUser()
  }

  const handleOtpLogin = (
    { token }: LoginOtpFormValues,
    {
      setSubmitting,
      validateForm,
    }: Pick<
      FormikHelpers<LoginOtpFormValues>,
      'setSubmitting' | 'validateForm'
    >,
  ) => {
    setSubmitting(true)

    async function loginUserWithOtp() {
      try {
        const errors = await validateForm()
        if (Object.values(errors).every((e) => !e)) {
          const session = await otpLogin(token)
          setSession(session)
          setAuthTokenCookie(session.token)
          setAuthToken(session.token)
          setMetaCookie(session)
          setLoggedIn(true)
        }
      } catch (e) {
        const requestError = e as RequestError
        if (requestError.status === 403) {
          setOtpLoginErrorMessage(t('logIn.accessDenied'))
        } else {
          setOtpLoginErrorMessage(requestError.reason)
        }
      } finally {
        setSubmitting(false)
      }
    }

    loginUserWithOtp()
  }

  const {
    handleGoogleLogin,
    googleSessionError,
    googleTokenId,
  } = useGoogleLogin(setLoggedIn)
  const {
    handleMicrosoftLogin,
    microsoftSessionError,
    microsoftTokenId,
    uniqueNonce,
  } = useMicrosoftLogin(setLoggedIn)

  useEffect(() => {
    if (!authToken && isLoggedIn) {
      setLoggedIn(false)
    }
  }, [authToken, isLoggedIn])

  return {
    authToken,
    session,
    isLoggedIn,
    handleLogin,
    handleOtpLogin,
    handleGoogleLogin,
    handleMicrosoftLogin,
    loginErrorMessage,
    otploginErrorMessage,
    googleSessionError,
    googleTokenId,
    microsoftSessionError,
    microsoftTokenId,
    uniqueNonce,
  }
}
