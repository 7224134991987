import {
  ApiError,
  ApiSession,
  TraderSettings,
  usePrevious,
  useSession,
  useSettings,
} from 'yordex-ui-kit'
import { fetchSession } from 'server/fetch/session/fetch'
import { fetchSettings } from 'server/fetch/settings/fetch'
import { isClientError } from 'utils/response/responseHelper'
import { useEffect, useState, useCallback } from 'react'
import { useAuth } from 'context/auth/AuthContext'
import { useLocation } from 'react-router-dom'
import { setUpPageTracking, trackPageChange } from 'utils/tracking/matomo'

export interface CommonData {
  session?: ApiSession
  settings?: TraderSettings
  unauthorized: boolean
  authToken?: string
  error?: unknown
}

export const useCommonData = (): CommonData => {
  const { authToken, setAuthToken } = useAuth()
  const [error, setError] = useState()
  const { session, setSession } = useSession()
  const { settings, setSettings, uiSettings, setUISettings } = useSettings()
  const [unauthorized, setUnauthorized] = useState(false)

  const setUnauthorizedFromStatus = useCallback(
    (status: number) => isClientError(status) && setUnauthorized(true),
    [],
  )

  const location = useLocation()
  const previousPathname = usePrevious(location.pathname)
  useEffect(() => {
    if (session && previousPathname && location.pathname !== previousPathname) {
      trackPageChange(location.pathname)
    }
  }, [session, location.pathname, previousPathname])

  // fetch session
  useEffect(() => {
    async function fetchSessionData() {
      try {
        const sessionData = await fetchSession()
        setSession(sessionData)
        if (sessionData) {
          setUpPageTracking(
            location.pathname,
            sessionData.traderId,
            sessionData.companyTradingName || 'UNKNOWN_COMPANY_NAME',
            sessionData.userId,
          )
        }
        if (!sessionData.traderId) {
          setAuthToken(undefined)
        }
      } catch (e) {
        setUnauthorizedFromStatus((e as ApiError).status)
        setAuthToken(undefined)
      }
    }

    if (
      !error &&
      ((!session && authToken) || (session && session.token !== authToken))
    ) {
      fetchSessionData()
    }
  }, [
    error,
    authToken,
    session,
    setUnauthorizedFromStatus,
    setSession,
    setAuthToken,
  ])

  // fetch settings
  useEffect(() => {
    async function fetchSettingsData() {
      if (session) {
        try {
          const { traderId, roles } = session
          const { uiSettings, settings } = await fetchSettings(traderId, roles)
          if (uiSettings) {
            setUISettings(uiSettings)
          }
          setSettings(settings)
        } catch (e) {
          setError(e as any)
          if (!process.env.JEST_WORKER_ID) {
            console.error(e)
          }
        }
      }
    }

    if (!error && session && session.traderId && authToken && !settings) {
      fetchSettingsData()
    }
  }, [
    error,
    authToken,
    session,
    setSettings,
    settings,
    setUISettings,
    uiSettings,
  ])

  useEffect(() => {
    if (error) {
      setAuthToken(undefined)
    }
  }, [error, setAuthToken])

  return {
    session,
    settings,
    unauthorized,
    authToken,
    error,
  }
}
