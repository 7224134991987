import { getAuthTokenCookie } from 'utils/cookies/cookiesHelper'
import { getConfig } from 'config'

const { bffUrl } = getConfig()

const getFileName = (headers: {
  get: (headerName: string) => string | null
}) => {
  const disposition = headers.get('content-disposition')
  if (disposition && disposition.includes('filename=')) {
    return disposition.split('filename=')[1]
  }
  return 'download.zip'
}

export type FileErrorMessage = 'FILE_NOT_FOUND' | 'UNEXPECTED_ERROR'

export const downloadFile = async (fileId: string) => {
  const response = await fetch(`${bffUrl}/files/${fileId}/download`, {
    method: 'GET',
    headers: {
      authorization: getAuthTokenCookie(),
      ['Content-Type']: 'application/octet-stream',
    } as HeadersInit,
  })
  if (response.ok) {
    const fileName = getFileName(response.headers)
    const blob = await response.blob()
    const url = global.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }
  } else {
    if (response.status === 404) {
      const message: FileErrorMessage = 'FILE_NOT_FOUND'
      throw new Error(message)
    } else {
      const message: FileErrorMessage = 'UNEXPECTED_ERROR'
      throw new Error(message)
    }
  }
}
