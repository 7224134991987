import { post } from 'utils/fetch/fetchHelper'

type StripeOnboardingRequest = {
  key: string
}

type StripOnboardingResponse = {
  url: string
}

export const fetchStripeOnboardingLink = (key: string, account: string) => {
  return post<StripeOnboardingRequest, StripOnboardingResponse>(
    `/benivo/stripe-onboarding/${account}`,
    { key },
  )
}
