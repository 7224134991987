import { FormikHelpers } from 'formik'
import { useState } from 'react'
import { resetPassword } from 'server/fetch/reset-password/fetch'
import { RequestError } from 'utils/fetch/fetchHelper'
import { FormValues } from '../ForgotPassword'

type FormHelpers = Pick<
  FormikHelpers<FormValues>,
  'setSubmitting' | 'validateForm'
>

export interface ResetPasswordState {
  errorMessage?: string
  handleResetPassword: (values: FormValues, helpers: FormHelpers) => void
  wasPasswordReset: boolean
  emailToCheck?: string
}

const useResetPassword = (): ResetPasswordState => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [wasPasswordReset, setWasPasswordReset] = useState(false)
  const [emailToCheck, setEmailToCheck] = useState('')

  const handleResetPassword = (
    { email }: FormValues,
    { setSubmitting, validateForm }: FormHelpers,
  ) => {
    setSubmitting(true)

    async function resetUsersPassword() {
      try {
        const errors = await validateForm()
        if (Object.values(errors).every((e) => !e)) {
          await resetPassword(email)
          setSubmitting(false)
          setEmailToCheck(email)
          setWasPasswordReset(true)
        }
      } catch (e) {
        const requestError = e as RequestError
        setErrorMessage(requestError.reason)
        setSubmitting(false)
      }
    }

    resetUsersPassword()
  }

  return { errorMessage, handleResetPassword, wasPasswordReset, emailToCheck }
}

export default useResetPassword
