import React, { useMemo, useState } from 'react'
import { ApiSession } from 'yordex-ui-kit'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import PublicLayout from 'components/public-layout/PublicLayout'
import { Formik } from 'formik'
import { object } from 'yup'
import {
  mobileNumberValidationSchema,
  mobileNumberCountryCodeValidationSchema,
} from 'utils/validation/user'
import { PhoneNumberForm } from '../phone-number-form/PhoneNumberForm'
import { useUpdateMobileNumber } from './hooks/useUpdateMobileNumber'

export interface VerifyPhoneNumberProps {
  session?: ApiSession
}

export interface MobileNumberFormValues {
  mobileNumber: string
  mobileNumberCountryCode: string
  phoneCountryCode: string
}

/* eslint-disable prettier/prettier */
const validationSchema = object().shape({
  mobileNumber: mobileNumberValidationSchema,
  mobileNumberCountryCode: mobileNumberCountryCodeValidationSchema
})
/* eslint-enable prettier/prettier */

export const VerifyPhoneNumber = ({ session }: VerifyPhoneNumberProps) => {
  const { t } = useTranslation()
  const [submitted, setSubmitted] = useState(false)

  const {
    handleUpdateMobileNumber,
    mobileNumberErrorMessage,
    wasMobileNumberProvided,
  } = useUpdateMobileNumber()

  const getSubtitle = useMemo(() => {
    if (wasMobileNumberProvided) {
      return t('logIn.verifyPhoneNumber')
    } else {
      return t('logIn.confirmPhoneNumber')
    }
  }, [wasMobileNumberProvided, t])

  return (
    <PublicLayout title={t('logIn.enterPhoneNumber')} subtitle={getSubtitle}>
      <Formik<MobileNumberFormValues>
        initialValues={{
          mobileNumber: '',
          mobileNumberCountryCode: '44',
          phoneCountryCode: 'GB',
        }}
        onSubmit={handleUpdateMobileNumber}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <PhoneNumberForm
            session={session}
            submitting={isSubmitting}
            submitted={submitted}
            errorMessage={mobileNumberErrorMessage}
            ismobileNumberVerified={wasMobileNumberProvided}
            onSubmit={() => setSubmitted(true)}
          />
        )}
      </Formik>
    </PublicLayout>
  )
}

export default VerifyPhoneNumber
