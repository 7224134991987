import { get } from 'utils/fetch/fetchHelper'

export type BankProvider = 'STRIPE' | 'RAILSBANK' | 'HIGHNOTE'
export type AccountStatus = 'ACTIVATED' | 'REQUESTED' | 'ARCHIVED'

export type YordexPayAccount = {
  provider: BankProvider
  currency: string
  status: AccountStatus
}

type YordexPayResponse = {
  accounts?: Array<YordexPayAccount>
}

export const fetchYordexPay = () => {
  return get<YordexPayResponse>(`/yordexpay`)
}

export const fetchPayoutsEnabled = async () => {
  const response = await get<Array<object>>(`/yordexpay/accounts`)

  return Boolean(response?.length)
}
