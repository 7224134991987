import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { verifyMobileNumber } from 'server/fetch/verify-mobile-phone/fetch'
import PublicLayout from 'components/public-layout/PublicLayout'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { PrimaryButton } from 'yordex-ui-kit'
import styles from './verify-mobile-phone.module.scss'

const VerifyMobilePhone = () => {
  const navigate = useNavigate()
  const { userId, verificationKey } = useParams<{
    userId: string
    verificationKey: string
  }>()
  const [hasError, setHasError] = useState(false)
  const [isPhoneVerfied, setIsPhoneVerfied] = useState(false)
  const { t } = useTranslation()

  const goToLogin = useCallback(() => {
    navigate('/login')
  }, [navigate])

  useEffect(() => {
    verifyMobileNumber(userId!, verificationKey!)
      .then(() => {
        setIsPhoneVerfied(true)
      })
      .catch(() => {
        setHasError(true)
      })
  }, [userId, verificationKey])

  if (hasError) {
    return (
      <PublicLayout
        title={t('logIn.error')}
        subtitle={t('logIn.verifyMobileNumberError')}
      >
        <PrimaryButton
          data-testid="log-in-button"
          onClick={goToLogin}
          className={styles['verify-mobile-phone__button']}
        >
          {t('forgotPassword.checkInboxBackLink')}
        </PrimaryButton>
      </PublicLayout>
    )
  }
  if (isPhoneVerfied) {
    /* eslint-disable prettier/prettier */
    return (
      <Navigate to={'/login'} state={{ phoneVerified: true }} />
    )
    /* eslint-enable prettier/prettier */
  }

  return null
}

export default VerifyMobilePhone
