import { useEffect } from 'react'
import { useState } from 'react'
import { mapLanguageCode } from 'server/fetch/user-location/mapLanguageCode'
import { RequestError } from 'utils/fetch/fetchHelper'

export const useGetLocationLanguage = () => {
  const [locationLanguage, setLocationLanguage] = useState<string>()
  useEffect(() => {
    getLocation()
  }, [])

  async function getLocation() {
    try {
      const language = navigator.language
      const languageCode = language.split('-')[0]
      const locationLanguage = mapLanguageCode(languageCode.toUpperCase())
      setLocationLanguage(locationLanguage)
    } catch (e) {
      const requestError = e as RequestError
      console.error(requestError.reason)
    }
  }

  return {
    locationLanguage,
  }
}
