import { useState } from 'react'
import { FormikHelpers } from 'formik'
import { ApiSession, useSession } from 'yordex-ui-kit'
import { updateMobileNumber } from 'server/fetch/login/fetch'
import { RequestError } from 'utils/fetch/fetchHelper'
import { MobileNumberFormValues } from '../VerifyPhoneNumber'

export interface UpdateMobileNumberState {
  handleUpdateMobileNumber: (
    values: MobileNumberFormValues,
    helpers: Pick<
      FormikHelpers<MobileNumberFormValues>,
      'setSubmitting' | 'validateForm'
    >,
  ) => void
  mobileNumberErrorMessage?: string
  session?: ApiSession
  authToken?: string
  wasMobileNumberProvided?: boolean
}

export const useUpdateMobileNumber = (): UpdateMobileNumberState => {
  const { session } = useSession()
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState<
    string | undefined
  >()
  const [wasMobileNumberProvided, setWasMobileNumberProvided] = useState(false)

  const handleUpdateMobileNumber = (
    {
      mobileNumber,
      mobileNumberCountryCode,
      phoneCountryCode,
    }: MobileNumberFormValues,
    {
      setSubmitting,
      validateForm,
    }: Pick<
      FormikHelpers<MobileNumberFormValues>,
      'setSubmitting' | 'validateForm'
    >,
  ) => {
    setSubmitting(true)

    async function updateUserMobileNumber() {
      try {
        const errors = await validateForm()
        if (Object.values(errors).every((e) => !e)) {
          if (session) {
            const { userId } = session
            await updateMobileNumber(
              mobileNumber,
              mobileNumberCountryCode,
              phoneCountryCode,
              userId,
            )
            setWasMobileNumberProvided(true)
          }
        }
      } catch (e) {
        const requestError = e as RequestError
        setMobileNumberErrorMessage(requestError.reason)
      } finally {
        setSubmitting(false)
      }
    }

    updateUserMobileNumber()
  }

  return {
    session,
    handleUpdateMobileNumber,
    mobileNumberErrorMessage,
    wasMobileNumberProvided,
  }
}
