import { useSession } from 'yordex-ui-kit'
import { googleLogin } from 'server/fetch/login/fetch'
import { RequestError } from 'utils/fetch/fetchHelper'
import { useAuth } from 'context/auth/AuthContext'
import { useState } from 'react'
import { setMetaCookie } from 'utils/cookies/cookiesHelper'

export interface GoogleLoginState {
  handleGoogleLogin: (tokenId: string) => void
  googleSessionError?: string
  googleTokenId?: string
}

export const useGoogleLogin = (
  setLoggedIn: (isLoggedIn: boolean) => void,
): GoogleLoginState => {
  const { setAuthToken } = useAuth()
  const { setSession } = useSession()
  const [googleSessionError, setGoogleSessionError] = useState<
    string | undefined
  >()
  const [googleTokenId, setGoogleTokenId] = useState<string | undefined>()

  const handleGoogleLogin = (tokenId: string) => {
    async function loginUserWithGoogle() {
      try {
        setGoogleSessionError(undefined)
        const session = await googleLogin(tokenId)
        setSession(session)
        setAuthToken(session.token)
        setMetaCookie(session)
        setLoggedIn(true)
        setGoogleTokenId(tokenId)
      } catch (e) {
        const requestError = e as RequestError
        setGoogleSessionError(requestError.reason)
      }
    }

    loginUserWithGoogle()
  }

  return {
    handleGoogleLogin,
    googleSessionError,
    googleTokenId,
  }
}
