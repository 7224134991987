interface Config {
  bffUrl?: string
  settingsHost?: string
  accountHost?: string
  tasksHost?: string
  ordersHost?: string
  cardsHost?: string
  googleClientId?: string
  microsoftClientId?: string
  angularLocalLogoutUrl?: string
  fallbackRouteHost?: string
  tosEnabled?: boolean
}

let config: Config | undefined

export const getConfig = (envVars = process.env): Config => {
  if (!config) {
    config = {
      bffUrl: envVars.REACT_APP_BFF_URL,
      settingsHost: envVars.REACT_APP_SETTINGS_HOST,
      accountHost: envVars.REACT_APP_ACCOUNT_HOST,
      tasksHost: envVars.REACT_APP_TASKS_HOST,
      ordersHost: envVars.REACT_APP_ORDERS_HOST,
      cardsHost: envVars.REACT_APP_CARDS_HOST,
      googleClientId: envVars.REACT_APP_GOOGLE_CLIENT_ID,
      microsoftClientId: envVars.REACT_APP_MICROSOFT_CLIENT_ID,
      angularLocalLogoutUrl: envVars.REACT_APP_ANGULAR_LOGOUT_LINK,
      fallbackRouteHost: envVars.REACT_APP_FALLBACK_ROUTE_HOST,
      tosEnabled: envVars.REACT_APP_TOS_ENABLED === 'true',
    }
  }
  return config
}
