import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

const SpendReportDetailsRedirect = () => {
  const params = useParams()

  return <Navigate to={`/finance/spend-reports/${params.id}`} replace />
}

export default SpendReportDetailsRedirect
