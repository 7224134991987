import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router'
import { getAngularAppUrl } from 'utils/navigation/navigationHelper'
import { useSettings } from 'yordex-ui-kit'

export const useAngularIfReactIsHidden = () => {
  const { settings } = useSettings()
  // Temporary fix for CS so they can use reset PIN
  // TODO Remove isCardSubpage condition when react is the default
  const location = useLocation()
  const isCardSubpage = location.pathname.includes('cards')

  const isReactHidden =
    !!(
      settings &&
      settings.angularUISettings &&
      !settings.angularUISettings.displayReact
    ) && !isCardSubpage

  useLayoutEffect(() => {
    if (isReactHidden) {
      window.location.assign(getAngularAppUrl())
    }
  }, [isReactHidden])

  return isReactHidden
}
