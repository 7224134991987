import { useState, useCallback } from 'react'
import { updateTermsOfService } from 'server/fetch/user/termsOfService'
import { isApiError, useSession } from 'yordex-ui-kit'

export const useSubmitToSModal = (close: () => void) => {
  const { session } = useSession()
  const [error, setError] = useState('')

  const updateTerms = useCallback(
    async (termsAccepted: boolean) => {
      if (!session?.userId) {
        return
      }

      try {
        setError('')
        await updateTermsOfService(session.userId, termsAccepted)
        close()
      } catch (e) {
        if (isApiError(e)) {
          setError(e.reason)
        } else {
          setError((e as Error).message)
        }
      }
    },
    [session, close, setError],
  )

  return {
    error,
    updateTerms,
  }
}
