import { Action } from 'server/fetch/reports/fetch'
import { TraderSettings, shouldDisplayBasedOnRoles } from 'yordex-ui-kit'

export type Tab =
  | 'PURCHASES'
  | 'EXPENSES'
  | 'ACCOUNTING'
  | 'MISSING_INVOICES'
  | 'PAYOUTS'
  | 'OWN_PAYMENTS'
  | 'UNMATCHED_INVOICES'

export const hasTabEnabled = (
  tabName: Tab,
  settings: TraderSettings | undefined,
) => {
  const tasksPage = settings?.angularUISettings?.pages?.['TASKS']
  const tasksVisibilityConfig = tasksPage && (tasksPage as any).tabs

  return tasksVisibilityConfig ? tasksVisibilityConfig.includes(tabName) : true
}

export const shouldShowOrdersTabs = (
  settings: TraderSettings | undefined,
  userRoles: Array<string> | undefined,
) => {
  const orderPage = settings?.angularUISettings?.pages?.['ORDER']
  return shouldDisplayBasedOnRoles(orderPage?.hiddenForUsers, userRoles)
}

export const shouldShowPaymentsTab = (roles: Array<string> | undefined) => {
  return (
    roles &&
    roles.some((role) =>
      [
        'ROLE_PAYMENTS_MANAGER',
        'ROLE_TRADER_ADMIN',
        'ROLE_TRADER_ADMIN_VIEW_ONLY',
      ].includes(role),
    )
  )
}

export const calculateTotalTasks = (
  actions: Array<Action>,
  totalInvoices: number,
  arePayoutsEnabled: boolean,
  settings: TraderSettings | undefined,
  roles: Array<string> | undefined,
) => {
  const expenseActions = actions.filter(
    (a) =>
      a.options && a.options[0] && a.options[0].action.startsWith('/expenses'),
  )
  const orderActions = actions.filter(
    (a) =>
      a.options && a.options[0] && a.options[0].action.startsWith('/orders'),
  )
  const purchasesTabCount = orderActions.filter(
    (a) =>
      a.options &&
      a.options[0] &&
      ['Approve', 'Confirm'].includes(a.options[0].actionName),
  ).length
  const expensesTabCount = expenseActions.filter(
    (a) =>
      a.options &&
      a.options[0] &&
      ['Submit expense', 'Approve'].includes(a.options[0].actionName),
  ).length
  const missingInvoicesTabCount = orderActions.filter(
    (a) =>
      a.options &&
      a.options[0] &&
      ['Upload invoice'].includes(a.options[0].actionName),
  ).length
  const unmatchedInvoicesTabCount = totalInvoices
  const payoutsTabCount = orderActions.filter(
    (a) =>
      a.options &&
      a.options[0] &&
      ['Approve Payment', 'Payout Pending'].includes(a.options[0].actionName),
  ).length
  const ownPaymentsTabCount = actions.filter(
    (a) =>
      a.options &&
      a.options[0] &&
      ['Mark as paid'].includes(a.options[0].actionName),
  ).length
  const accountingTabCount = actions.filter(
    (a) =>
      a.options &&
      a.options[0] &&
      ['Mark as synced'].includes(a.options[0].actionName),
  ).length

  let totalTasks = 0
  if (
    shouldShowOrdersTabs(settings, roles) &&
    hasTabEnabled('PURCHASES', settings)
  ) {
    totalTasks += purchasesTabCount
  }
  if (hasTabEnabled('EXPENSES', settings)) {
    totalTasks += expensesTabCount
  }
  if (
    shouldShowOrdersTabs(settings, roles) &&
    hasTabEnabled('MISSING_INVOICES', settings)
  ) {
    totalTasks += missingInvoicesTabCount
  }
  if (
    shouldShowOrdersTabs(settings, roles) &&
    hasTabEnabled('UNMATCHED_INVOICES', settings)
  ) {
    totalTasks += unmatchedInvoicesTabCount
  }
  if (arePayoutsEnabled && hasTabEnabled('PAYOUTS', settings)) {
    totalTasks += payoutsTabCount
  }
  if (shouldShowPaymentsTab(roles) && hasTabEnabled('OWN_PAYMENTS', settings)) {
    totalTasks += ownPaymentsTabCount
  }
  if (shouldShowPaymentsTab(roles) && hasTabEnabled('ACCOUNTING', settings)) {
    totalTasks += accountingTabCount
  }

  return totalTasks
}
