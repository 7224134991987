import { useMemo } from 'react'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { FooterItem } from 'yordex-ui-kit'

const useFooterItems = (): FooterItem[] => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        id: 'terms',
        title: t('footerLinks.terms'),
        href: 'https://yordex.com/terms-of-use',
      },
      {
        id: 'privacy',
        title: t('footerLinks.privacy'),
        href: 'https://yordex.com/privacy',
      },
      {
        id: 'europevisacards',
        title: t('footerLinks.europeVisaCards'),
        href: `https://www.yordex.com/europe-visa-cards-end-user-corporate-terms-and-conditions/`,
      },
      {
        id: 'railsbank',
        title: t('footerLinks.railsbank'),
        href: 'https://www.yordex.com/railsbank-terms-and-conditions/',
      },
      {
        id: 'payrnet',
        title: t('footerLinks.payrnet'),
        href: 'https://www.yordex.com/payrnet-terms-and-conditions/',
      },
      {
        id: 'copyright',
        title: t('footerLinks.copyrightDated', {
          year: new Date().getFullYear(),
        }),
      },
    ],
    [t],
  )
}

export default useFooterItems
