import { useEffect, useState } from 'react'
import { fetchEnvironment, Environment } from 'server/fetch/environment/fetch'

export const useEnvironment = () => {
  const [environment, setEnvironment] = useState<Environment | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!environment) {
      const fetchEnvironmentAsync = async () => {
        const env = await fetchEnvironment()
        setEnvironment(env)
        setIsLoading(false)
      }

      setIsLoading(true)
      fetchEnvironmentAsync()
    }
  }, [environment])

  return !isLoading ? environment : undefined
}
