const inRangeFrom = (start: number) => {
  if (Number.isNaN(start)) {
    throw new Error(`Expected numerical start value but found ${start}`)
  }

  const to = start + 100 // e.g. 300

  return (code: number) => code >= start && code < to
}

export const isInformational = inRangeFrom(100)
export const isSuccess = inRangeFrom(200)
export const isRedirection = inRangeFrom(300)
export const isClientError = inRangeFrom(400)
export const isServerError = inRangeFrom(500)
