import { useEffect, useState } from 'react'
import { ApiSession, TraderSettings } from 'yordex-ui-kit'
import { fetchActionsForUser } from 'server/fetch/reports/fetch'
import { fetchTraderOrdersForUser } from 'server/fetch/traderorders/fetch'
import { fetchTraderInformation } from 'server/fetch/traders/fetch'
import {
  calculateTotalTasks,
  hasTabEnabled,
  shouldShowOrdersTabs,
  shouldShowPaymentsTab,
} from '../utils/tasks'
import { fetchPayoutsEnabled } from 'server/fetch/yordexpay/fetch'

interface NavigationData {
  tasksCount?: number
  invoicesCount?: number
  traderSlug?: string
}

const useNavigationData = (session?: ApiSession, settings?: TraderSettings) => {
  const [navigationData, setNavigationData] = useState<NavigationData>({})

  useEffect(() => {
    async function fetchNavigationData() {
      if (session) {
        const { userId, traderId } = session

        const arePayoutsEnabled = await fetchPayoutsEnabled()

        const tabs = {
          MARK_AS_SYNCED: Boolean(
            shouldShowPaymentsTab(session?.roles) &&
              hasTabEnabled('ACCOUNTING', settings),
          ),
          MARK_AS_PAID: Boolean(
            shouldShowPaymentsTab(session?.roles) &&
              hasTabEnabled('OWN_PAYMENTS', settings),
          ),
          PAYOUT: Boolean(
            arePayoutsEnabled && hasTabEnabled('PAYOUTS', settings),
          ),
          UPLOAD_INVOICE: Boolean(
            shouldShowOrdersTabs(settings, session?.roles) &&
              hasTabEnabled('MISSING_INVOICES', settings),
          ),
          CONFIRM: Boolean(
            shouldShowOrdersTabs(settings, session?.roles) &&
              hasTabEnabled('PURCHASES', settings),
          ),
          APPROVE: Boolean(
            hasTabEnabled('PURCHASES', settings) ||
              hasTabEnabled('EXPENSES', settings),
          ),
        }

        const [{ actions = [] }, { page }, { traderSlug }] = await Promise.all([
          fetchActionsForUser(userId, tabs),
          fetchTraderOrdersForUser({ userId, status: 'MATCHING' }),
          fetchTraderInformation(traderId),
        ])
        const { totalElements = 0 } = page
        setNavigationData({
          tasksCount: calculateTotalTasks(
            actions,
            totalElements,
            arePayoutsEnabled,
            settings,
            session.roles,
          ),
          invoicesCount: totalElements,
          traderSlug,
        })
      }
    }

    fetchNavigationData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return navigationData
}

export default useNavigationData
