import React, { Suspense, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { getConfig } from 'config'
import {
  getAuthTokenCookie,
  setAuthTokenCookie,
} from 'utils/cookies/cookiesHelper'
import { AuthContext } from 'context/auth/AuthContext'
import {
  SessionContext,
  SettingsContext,
  TraderSettings,
  ApiSession,
  ErrorBoundary,
  NotFound,
  UISettings,
  ThemeProvider,
} from 'yordex-ui-kit'
import PrivateRoute from 'components/private-route/PrivateRoute'
import Login from 'templates/login/Login'
import ForgotPassword from 'templates/forgot-password/ForgotPassword'
import ChooseNewPassword from 'templates/choose-new-password/ChooseNewPassword'
import { microFrontendFactory } from 'components/micro-frontend/factory'
import Logout from 'templates/logout/Logout'
import './App.module.scss'
import FallbackRoute from 'components/fallback-route/FallbackRoute'
import VerifyMobilePhone from 'templates/verify-mobile-phone/VerifyMobilePhone'
import { logError } from 'server/fetch/log-error/logError'
import DownloadFile from 'templates/download-file/DownloadFile'
import SpendReportDetailsRedirect from 'components/old-routing-redirects/SpendReportDetailsRedirect'
import BenivoStripeSetup from 'templates/benivo-stripe-setup/BenivoStripeSetup'
import ToSModal from 'components/tos-modal/ToSModal'
import PageMeta from 'components/page-meta/PageMeta'

const {
  accountHost = '',
  ordersHost = '',
  cardsHost = '',
  settingsHost = '',
} = getConfig()

const YordexUiAccount = microFrontendFactory(accountHost, 'Account')

const YordexUiOrders = microFrontendFactory(ordersHost, 'Orders')

const YordexUiCards = microFrontendFactory(cardsHost, 'Cards')

const YordexUiSettings = microFrontendFactory(settingsHost, 'Settings')

const App = () => {
  const [authToken, setAuthToken] = useState(getAuthTokenCookie())
  const [session, setSession] = useState<ApiSession>()
  /* eslint-disable prettier/prettier */
  const [settings, setSettings] = useState<
    TraderSettings
  >()
  const [uiSettings, setUISettings] = useState<
    UISettings
  >()
  /* eslint-enable prettier/prettier */

  const setToken = (data: string | undefined) => {
    setAuthTokenCookie(data)
    setAuthToken(data)
  }

  return (
    <ErrorBoundary handleError={logError}>
      <ThemeProvider>
        <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
          <SessionContext.Provider value={{ session, setSession }}>
            <SettingsContext.Provider
              value={{
                settings,
                setSettings,
                uiSettings,
                setUISettings,
              }}
            >
              <Suspense fallback="">
                <PageMeta />
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Navigate to="/spend" />} />
                    {/* public routes */}
                    <Route
                      path="/benivo/stripe-onboarding/:account/:key"
                      element={<BenivoStripeSetup />}
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route
                      path="/mobile-verification/:userId/:verificationKey"
                      element={<VerifyMobilePhone />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/password-reset/:email/:passwordResetKey"
                      element={<ChooseNewPassword />}
                    />

                    {/* private with navigation */}
                    <Route element={<PrivateRoute />}>
                      <Route
                        path="/settings/account/*"
                        element={<YordexUiAccount />}
                      />
                      <Route
                        path="/settings/migrator"
                        element={<YordexUiSettings />}
                      />
                      <Route
                        path="/settings/api-keys"
                        element={<YordexUiSettings />}
                      />
                      <Route
                        path={'/settings/users/*'}
                        element={<YordexUiSettings />}
                      />
                      <Route
                        path={'/suppliers/*'}
                        element={<YordexUiSettings />}
                      />
                      <Route path={'/spend/*'} element={<YordexUiOrders />} />
                      <Route
                        path={'/finance/payment-run'}
                        element={<YordexUiOrders />}
                      />
                      <Route path={'/cards/*'} element={<YordexUiCards />} />
                      <Route
                        path={'/finance/statement'}
                        element={<YordexUiCards />}
                      />
                      <Route
                        path={'/finance/spend-reports/*'}
                        element={<YordexUiOrders />}
                      />
                    </Route>

                    {/* private without navigation */}
                    <Route element={<PrivateRoute withoutNavigation />}>
                      <Route
                        path="/files/:fileId/download"
                        element={<DownloadFile />}
                      />
                    </Route>

                    {/* old routes */}
                    <Route
                      element={<Navigate to="/finance/spend-reports" replace />}
                      path="/reports/spend"
                    />
                    <Route
                      element={
                        <Navigate to="/finance/spend-reports/new" replace />
                      }
                      path="/reports/spend/new"
                    />
                    <Route
                      element={<SpendReportDetailsRedirect />}
                      path="/reports/spend/:id"
                    />
                    <Route
                      element={<Navigate to="/finance/statement" replace />}
                      path="/reports/statement"
                    />
                    <Route
                      element={<Navigate to="/finance/payment-run" replace />}
                      path="/finance/payouts"
                    />

                    <Route element={<FallbackRoute />}>
                      <Route path="/purchases" />
                      <Route path="/orders/new" />
                      <Route path="/orders/:id" />
                      <Route path="/expenses/new" />
                      <Route path="/expenses/:id" />
                      <Route path="/contacts/new" />
                      <Route path="/tasks" />
                      <Route path="/tasks/expenses" />
                      <Route path="/reports/suppliers" />
                      <Route path="/contacts/*" />
                      <Route path="/invoices" />
                      <Route path="/settings/terms/*" />
                      <Route path="/settings/services" />
                      <Route path="/settings/delegates" />
                      <Route path="/settings/costallocations" />
                      <Route path="/settings/profile" />
                    </Route>

                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </BrowserRouter>
                <ToSModal />
              </Suspense>
            </SettingsContext.Provider>
          </SessionContext.Provider>
        </AuthContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
