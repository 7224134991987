import React, { ComponentType } from 'react'
import MicrosoftLogin from 'react-microsoft-login'
import { Button, useSession } from 'yordex-ui-kit'
import { getConfig } from 'config'
import { ErrorSetter } from 'context/error/ErrorContext'
import { useSetGlobalError } from '../SocialLoginButtons'
import MicrosoftLogo from 'assets/logo-microsoft.svg'
import styles from '../social-login-buttons.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

const { microsoftClientId } = getConfig()
export interface Props {
  handleMicrosoftLogin: (tokenId: string, nonce: string) => void
  microsoftSessionError?: string
  setError: ErrorSetter
}

const MicrosoftLoginButton = MicrosoftLogin as ComponentType<{
  clientId: string
  authCallback: (error: any, data: any) => void
  redirectUri: string
  children: React.ReactNode
}>

const MicrosoftButton = ({
  handleMicrosoftLogin,
  microsoftSessionError,
  setError,
}: Props) => {
  const { t } = useTranslation()
  useSetGlobalError('Microsoft', setError, microsoftSessionError)
  const { session } = useSession()

  const authHandler = (err: any, data: any) => {
    if (err && err.errorCode === 'user_cancelled') {
      return
    }

    if (err) {
      setError({
        title: t('logIn.microsoft.errorTitle'),
        description: t('logIn.microsoft.errorDescription'),
      })
      return
    }

    if (!session) {
      setError(undefined)
      handleMicrosoftLogin(data.idToken.rawIdToken, data.idToken.nonce)
    } else {
      console.warn('Microsoft log in called after the session is loaded')
    }
  }

  return (
    <div className={styles['social-login-buttons__microsoft-container']}>
      <MicrosoftLoginButton
        clientId={microsoftClientId!}
        authCallback={authHandler}
        redirectUri={window.location.origin}
      >
        <Button large className={styles['social-login-buttons__button']}>
          <>
            <img
              src={MicrosoftLogo}
              className={styles['social-login-buttons__logo']}
            />{' '}
            {t('logIn.microsoft.button')}
          </>
        </Button>
      </MicrosoftLoginButton>
    </div>
  )
}

export default MicrosoftButton
