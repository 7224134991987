// @ts-ignore
const matomoArray: Array<Array<string | number>> = window._paq || []

export const trackPageChange = (pathname: string) => {
  setCustomUrl(pathname)
  matomoArray.push(['trackPageView'])
}

const setCustomUrl = (pathname: string) => {
  const uuidRegex = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/
  const queryRegex = /\?.+/
  matomoArray.push([
    'setCustomUrl',
    pathname.replace(uuidRegex, ':id').replace(queryRegex, ''),
  ])
}

export const setUpPageTracking = (
  pathname: string,
  traderId: string,
  traderName: string,
  userId: string,
) => {
  setCustomUrl(pathname)
  // 1rst custom dimension is the traderId
  matomoArray.push(['setCustomDimension', 1, traderId])
  matomoArray.push(['setCustomDimension', 3, traderName])
  matomoArray.push(['setUserId', userId])
  matomoArray.push(['enableLinkTracking'])
  matomoArray.push(['trackPageView'])
}
