import { post } from 'utils/fetch/fetchHelper'

export const verifyMobileNumber = (
  userId: string,
  mobileVerificationToken: string,
) =>
  post<{ mobileVerificationToken: string }, void>(
    `/users/${userId}/otp/mobile/verify`,
    {
      mobileVerificationToken,
    },
  )
