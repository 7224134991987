import React from 'react'
import { Alert } from 'yordex-ui-kit'
import styles from '../tos-modal.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

const TosDeclineConfirmationModalContent = () => {
  const { t } = useTranslation()

  return (
    <Alert className={styles['tos-modal__alert']} type="warning">
      {t('tosModal.warningAlert')}
    </Alert>
  )
}

export default TosDeclineConfirmationModalContent
