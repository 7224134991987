import React, { useMemo } from 'react'
import { Navigation, useSession, useSettings } from 'yordex-ui-kit'
import {
  getVisibleCategoriesForUserSettings,
  markActiveCategory,
} from 'utils/navigation/navigationHelper'
import useNavigationData from './hooks/useNavigationData'
import useCategories from './hooks/useCategories'
import TraderSelect from './trader-select/TraderSelect'
import { useLocation } from 'react-router-dom'

const PrivateNavigation = () => {
  const { session } = useSession()
  const { settings, uiSettings } = useSettings()
  const navigationData = useNavigationData(session, settings)
  const categories = useCategories()
  const location = useLocation()
  const visibleCategories = useMemo(
    () =>
      markActiveCategory(
        getVisibleCategoriesForUserSettings(settings, uiSettings, categories),
        location.pathname,
      ),
    [settings, categories, uiSettings, location.pathname],
  )

  const traderTokens = session && session.traderTokens

  // filter sections from navigation categories based on user settings
  if (!session) {
    return null
  }

  return (
    <Navigation
      traderSelect={
        traderTokens && traderTokens.length ? (
          <TraderSelect traderTokens={traderTokens} session={session} />
        ) : null
      }
      logoUrl={uiSettings && uiSettings.logo}
      categories={visibleCategories}
      {...navigationData}
    />
  )
}

export default PrivateNavigation
