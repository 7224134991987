import PublicLayout from 'components/public-layout/PublicLayout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { Navigate, useParams } from 'react-router-dom'
import { downloadFile, FileErrorMessage } from 'server/fetch/file/download'
import { Loader, NotFound, UnexpectedError } from 'yordex-ui-kit'
import styles from './download-file.module.scss'

const DownloadFile = () => {
  const { t } = useTranslation()
  const { fileId } = useParams<{ fileId: string }>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<FileErrorMessage | undefined>()

  useEffect(() => {
    const triggerDownload = async () => {
      try {
        await downloadFile(fileId!)
        setLoading(false)
      } catch (error) {
        setError((error as { message: FileErrorMessage }).message)
      }
    }
    triggerDownload()
  }, [fileId])

  if (!loading) {
    return <Navigate to="/" />
  }

  if (error) {
    if (error === 'FILE_NOT_FOUND') {
      return <NotFound />
    }
    return <UnexpectedError />
  }

  return (
    <PublicLayout
      title={t('downloadDocument.title')}
      subtitle={t('downloadDocument.description')}
    >
      <div className={styles['download-file__container']}>
        <Loader data-testid="file-loader" />
      </div>
    </PublicLayout>
  )
}

export default DownloadFile
