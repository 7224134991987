import axios from 'axios'
import { History } from 'history'
import { useEnvironment } from 'hooks/useEnvironment/useEnvironment'
import React, { useContext, useEffect, useState } from 'react'
import { UNSAFE_NavigationContext, useLocation } from 'react-router-dom'
import {
  UnexpectedError,
  usePrevious,
  useSession,
  useSettings,
} from 'yordex-ui-kit'
import styles from './microfrontend.module.scss'

interface MicroFrontendProps {
  name: string
  window?: any
  host: string
  document?: any
}

const MicroFrontend: React.FC<MicroFrontendProps> = ({
  name,
  window,
  host,
  document,
}: MicroFrontendProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { session } = useSession()
  const { uiSettings, settings } = useSettings()
  const environment = useEnvironment()
  const [error, setError] = React.useState<boolean>(false)
  const location = useLocation()
  // This is the only way to get the history used by BrowserRouter to share it with the MFE as for react router 6.4.3
  const history = useContext(UNSAFE_NavigationContext).navigator as History

  useEffect(() => {
    setIsLoaded(false)
    if (!environment) {
      return
    }
    const renderMicroFrontend = () => {
      window[`render${name}`](`${name}-container`, {
        host,
        session,
        uiSettings,
        settings,
        environment,
        location,
        history,
      })
      setIsLoaded(true)
    }

    const scriptId = `micro-frontend-script-${name}`

    if (document.getElementById(scriptId)) {
      return renderMicroFrontend()
    }

    axios
      .get(`${host}/asset-manifest.json`)
      .then(({ data: manifest }: any) => {
        const script = document.createElement('script')
        script.id = scriptId
        script.crossOrigin = ''
        script.src = `${host}${manifest.files['main.js']}`
        script.onload = renderMicroFrontend
        document.body.appendChild(script)

        const mainCss = manifest.files['main.css']
        if (mainCss) {
          const link = document.createElement('link')
          link.href = `${host}${mainCss}`
          link.rel = 'stylesheet'
          document.head.appendChild(link)
        }
      })
      .catch(() => {
        setError(true)
      })

    return () => {
      if (window[`unmount${name}`]) {
        window[`unmount${name}`]()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment, name])

  const previousName = usePrevious(name)
  useEffect(() => {
    // navigation update within a mounted microfrontend
    if (isLoaded && name === previousName) {
      window[`rerender${name}`]({
        host,
        session,
        uiSettings,
        settings,
        environment,
        location,
        history,
      })
    }
  }, [
    isLoaded,
    location,
    name,
    previousName,
    host,
    session,
    uiSettings,
    settings,
    environment,
    history,
    window,
  ])

  if (error) {
    return <UnexpectedError />
  }

  return (
    <main
      id={`${name}-container`}
      data-testid={`${name}-container`}
      className={styles['microfrontend']}
    />
  )
}

MicroFrontend.defaultProps = {
  document,
  window,
}

export default MicroFrontend
