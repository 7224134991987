import { get } from 'utils/fetch/fetchHelper'

export type Environment = 'test' | 'dev' | 'prod'
interface EnvironmentDto {
  environment: Environment
}
export const fetchEnvironment = async (): Promise<Environment> => {
  const { environment } = await get<EnvironmentDto>(`/environment`)
  return environment
}
