import React, { ReactNode, useState } from 'react'
import { ReactComponent as YordexLogo } from 'yordex-ui-kit/assets/logos/yordex-full.svg'
import { ReactComponent as EburyLogo } from 'yordex-ui-kit/assets/logos/ebury.svg'
import classnames from 'classnames'
import { Alert, Link } from 'yordex-ui-kit'
import { ErrorContext, ErrorMessage } from 'context/error/ErrorContext'
import styles from './public-layout.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { isEbury } from 'utils/ebury/isEbury'

export interface Props {
  children: ReactNode
  title: string
  subtitle: ReactNode
  subtitleClassName?: string
  footer?: ReactNode
  languageSelector?: ReactNode
}

const PublicLayout = ({
  children,
  title,
  subtitle,
  subtitleClassName,
  footer,
  languageSelector,
}: Props) => {
  const { t } = useTranslation()
  const [error, setError] = useState<ErrorMessage | undefined>()

  const logo = isEbury() ? (
    <>
      <EburyLogo
        className={styles['public-layout__ebury-logo']}
        height="35"
        width="99"
        data-testid="ebury-logo"
      />
      <p className={styles['public-layout__languageSelector']}>
        {languageSelector}
      </p>
    </>
  ) : (
    <>
      <YordexLogo
        className={styles['public-layout__yordex-logo']}
        height="35"
        width="162"
        data-testid="yordex-logo"
      />
      <p className={styles['public-layout__languageSelector']}>
        {languageSelector}
      </p>
    </>
  )

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      <div className={styles['public-layout']}>
        <div className={styles['public-layout__logo-container']}>{logo}</div>
        {error && (
          <Alert
            className={styles['public-layout__global-error']}
            type="error"
            title={error.title}
            description={error.description}
          />
        )}
        <div className={styles['public-layout__container']}>
          <h1 className={styles['public-layout__title']}>{title}</h1>
          <p
            className={classnames(
              styles['public-layout__subtitle'],
              subtitleClassName,
            )}
          >
            {subtitle}
          </p>
          {children}
        </div>
        {footer}
        <div className={styles['public-layout__footer-links']}>
          <div>{t('footerLinks.copyright')}</div>
          <div>
            <Link
              className={styles['public-layout__link']}
              external
              href="https://www.yordex.com/privacy"
            >
              {t('footerLinks.privacy')}
            </Link>
            <span className={styles['public-layout__dot']}>&#8226;</span>
            <Link
              className={styles['public-layout__link']}
              external
              href="https://www.yordex.com/terms-of-use"
            >
              {t('footerLinks.terms')}
            </Link>
          </div>
        </div>
      </div>
    </ErrorContext.Provider>
  )
}

export default PublicLayout
