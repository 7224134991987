/**
 * Redirects provided path to angular app
 */

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getConfig } from 'config'
import { getSubdomain } from 'utils/navigation/navigationHelper'

const { fallbackRouteHost } = getConfig()
const REDIRECT_HOST = fallbackRouteHost || `https://${getSubdomain()}`

const FallbackRoute = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.location.replace(`${REDIRECT_HOST}${pathname}`)
  }, [pathname])

  return <></>
}
export default FallbackRoute
