import axios, { AxiosResponse } from 'axios'
import { getConfig } from 'config'
import { getAuthTokenCookie, getMetaCookie } from 'utils/cookies/cookiesHelper'

const { bffUrl } = getConfig()

export interface RequestError {
  reason: string
  status: number
}

const handleResponse = <ResponseData>(
  request: Promise<AxiosResponse<ResponseData>>,
) => {
  return request
    .then((res) => res.data)
    .catch((e) => {
      const errorData = e.response && e.response.data && e.response.data
      const reason =
        (errorData &&
          errorData.errors &&
          e.response.data.errors[0] &&
          e.response.data.errors[0].message) ||
        (errorData && errorData.detail) ||
        'Something went wrong, please try again later'
      const status = (e.response && e.response.status) || 400
      return Promise.reject({ reason, status })
    })
}

export function get<ResponseData>(path: string) {
  return handleResponse(
    axios.get<ResponseData>(`${bffUrl}${path}`, {
      headers: {
        authorization: getAuthTokenCookie(),
        'x-yordex-metadata': getMetaCookie() as string,
      },
    }),
  )
}

export function post<RequestData, ResponseData>(
  path: string,
  data: RequestData,
) {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  const metaCookie = getMetaCookie()
  if (metaCookie) {
    headers['x-yordex-metadata'] = metaCookie
  }
  const authorization = getAuthTokenCookie()
  if (authorization) {
    headers.authorization = authorization
  }

  return handleResponse(
    axios.post<RequestData, AxiosResponse<ResponseData>>(
      `${bffUrl}${path}`,
      data,
      {
        headers,
      },
    ),
  )
}

export function patch<Request, Response>(path: string, data: Request) {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  const metaCookie = getMetaCookie()
  if (metaCookie) {
    headers['x-yordex-metadata'] = metaCookie
  }
  const authorization = getAuthTokenCookie()
  if (authorization) {
    headers.authorization = authorization
  }

  return handleResponse(
    axios.patch<Request, AxiosResponse<Response>>(`${bffUrl}${path}`, data, {
      headers,
    }),
  )
}

export function deleteRequest<ResponseData>(path: string) {
  const headers: any = {
    'Content-Type': 'application/json',
    authorization: getAuthTokenCookie(),
    'x-yordex-metadata': getMetaCookie(),
  }

  return handleResponse(
    axios.delete<AxiosResponse<ResponseData>>(`${bffUrl}${path}`, {
      headers,
    }),
  )
}

export function getExternal<ResponseData>(path: string) {
  return handleResponse(axios.get<ResponseData>(path))
}
