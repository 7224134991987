import React from 'react'
import { useField, Form } from 'formik'
import {
  PrimaryButton,
  Input,
  FormField,
  Alert,
  ApiSession,
} from 'yordex-ui-kit'
import styles from './login-sms.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

interface Props {
  submitting: boolean
  submitted: boolean
  newTokenInfo: boolean
  errorMessage?: string
  onSubmit: () => void
  session?: ApiSession
}

export const LoginSms = ({
  submitting,
  submitted,
  newTokenInfo,
  errorMessage,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()
  const [tokenField, tokenMeta] = useField('token')

  return (
    <Form className={styles['login-sms']} noValidate>
      {newTokenInfo && (
        <Alert className={styles['login-sms__error-message']} type="info">
          {t('logIn.newToken')}
        </Alert>
      )}
      {errorMessage && (
        <Alert className={styles['login-sms__error-message']} type="error">
          {errorMessage}
        </Alert>
      )}
      <FormField
        className={styles['login-sms__input']}
        id="token"
        label={{ name: t('logIn.otpToken') }}
        errorMessage={tokenMeta.error}
        submitting={submitting}
        submitted={submitted}
        required={true}
      >
        <Input
          {...tokenField}
          id="token"
          large
          required
          type="text"
          autoFocus
          disabled={submitting}
          error={submitted && !!tokenMeta.error}
        />
      </FormField>
      <PrimaryButton
        type="submit"
        data-testid="log-in-button"
        large
        disabled={submitting}
        className={styles['login-sms__button']}
        onClick={onSubmit}
      >
        {t('logIn.form.logInBtn')}
      </PrimaryButton>
    </Form>
  )
}
