/* istanbul ignore file */

import PublicLayout from 'components/public-layout/PublicLayout'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchStripeOnboardingLink } from 'server/fetch/benivo/fetchStripeOnboardingLink'
import { Alert } from 'yordex-ui-kit'

const BenivoStripeSetup = () => {
  const params = useParams()
  const key = params.key || ''
  const account = params.account || ''

  const [error, setError] = useState(false)

  useEffect(() => {
    const getStripeUrl = async () => {
      try {
        const { url } = await fetchStripeOnboardingLink(key, account)
        window.location.replace(url)
      } catch (error) {
        setError(true)
      }
    }

    getStripeUrl()
  }, [key, account])

  if (error) {
    return (
      <PublicLayout title="Error occurred" subtitle="">
        <Alert type="error">
          No account found or wrong authentication key. Please contact support.
        </Alert>
      </PublicLayout>
    )
  }

  return null
}

export default BenivoStripeSetup
