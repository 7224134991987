import React from 'react'
import { useField, Form } from 'formik'
import { PrimaryButton, InputPassword, FormField, Alert } from 'yordex-ui-kit'
import styles from './new-password-form.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

interface Props {
  submitting: boolean
  submitted: boolean
  onSubmit: () => void
  errorMessage?: string
}

const NewPasswordForm = ({
  submitted,
  submitting,
  onSubmit,
  errorMessage,
}: Props) => {
  const { t } = useTranslation()
  const [newPasswordField, newPasswrodMeta] = useField('newPassword')
  const [confirmPasswordField, confirmPasswordMeta] = useField(
    'confirmPassword',
  )

  return (
    <Form noValidate className={styles['new-password-form']}>
      {errorMessage && (
        <Alert
          className={styles['new-password-form__error-message']}
          type="error"
        >
          {errorMessage}
        </Alert>
      )}
      <FormField
        className={styles['new-password-form__input']}
        id="new-password"
        label={{ name: t('passwordReset.form.newPassword') }}
        errorMessage={newPasswrodMeta.error}
        submitting={submitting}
        submitted={submitted}
        instruction={
          newPasswrodMeta.error && submitted
            ? undefined
            : t('passwordReset.form.newPasswordInstruction')
        }
        required={true}
      >
        <InputPassword
          id="new-password"
          {...newPasswordField}
          error={submitted && !!newPasswrodMeta.error}
          type="password"
          disabled={submitting}
          large
          required
          passwordToggle
        />
      </FormField>
      <FormField
        className={styles['new-password-form__input']}
        id="confirm-password"
        label={{ name: t('passwordReset.form.confirmPassword') }}
        errorMessage={confirmPasswordMeta.error}
        submitting={submitting}
        submitted={submitted}
        required={true}
      >
        <InputPassword
          id="confirm-password"
          {...confirmPasswordField}
          type="password"
          large
          required
          passwordToggle={true}
          disabled={submitting}
          error={submitted && !!confirmPasswordMeta.error}
        />
      </FormField>
      <PrimaryButton
        type="submit"
        large
        disabled={submitting}
        onClick={onSubmit}
        className={styles['new-password-form__button']}
      >
        {t('passwordReset.form.submitBtn')}
      </PrimaryButton>
    </Form>
  )
}

export default NewPasswordForm
