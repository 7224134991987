import { createContext, useContext } from 'react'
import { noop } from 'utils/noop'

export interface AuthContextProps {
  authToken?: string
  setAuthToken: (value: string | undefined) => void
}

export const AuthContext = createContext<AuthContextProps>({
  setAuthToken: noop,
})

export const useAuth = () => useContext(AuthContext)
