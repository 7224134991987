import { useEffect } from 'react'
import { getConfig } from 'config'
import { deleteSession } from 'server/fetch/session/fetch'
import { setAuthTokenCookie, setMetaCookie } from 'utils/cookies/cookiesHelper'
import { getSubdomain } from 'utils/navigation/navigationHelper'
import { googleLogout } from '@react-oauth/google'
import { withGoogleContext } from 'context/google/withGoogleContext'
import { isEbury } from 'utils/ebury/isEbury'

const logoutGoogleIfTheSessionIsActive = () => {
  try {
    googleLogout()
  } catch (e) {
    // ignore the error if the session is no longer active
  }
}

const { angularLocalLogoutUrl } = getConfig()
const logoutMicrosoft = () => sessionStorage.removeItem('msal.idtoken')

const logoutLink =
  angularLocalLogoutUrl ||
  (isEbury()
    ? `https://ebury.${getSubdomain()}/login`
    : `https://${getSubdomain()}/logout`)

const Logout = () => {
  useEffect(() => {
    async function logout() {
      try {
        await deleteSession()
      } finally {
        logoutMicrosoft()
        logoutGoogleIfTheSessionIsActive()
        setAuthTokenCookie(undefined)
        setMetaCookie(undefined)
        window.location.replace(logoutLink)
      }
    }

    logout()
  }, [])

  return null
}

export default withGoogleContext(Logout)
