export const mapLanguageCode = (languageCode: string): string => {
  const languageCodes = ['EN', 'ES', 'FR', 'NL', 'PL']

  let locationLanguage = 'EN'

  languageCodes.map((code) => {
    if (languageCode === code) {
      locationLanguage = languageCode
    }
  })

  return locationLanguage
}
