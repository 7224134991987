import React from 'react'
import { Alert, Checkbox, FormField, Link } from 'yordex-ui-kit'
import styles from '../tos-modal.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

const TERMS_OF_SERVICE_URL =
  'https://www.yordex.com/legal/stripe-us-cardholder-terms-of-service'
const FAQ_URL = 'https://help.yordex.com/terms-of-service-faqs'

type ToSAcceptanceModalProps = {
  isSubmitted: boolean
  isSubmitting: boolean
  checkboxError?: string
  termsOfServiceRead: boolean
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ToSAcceptanceModalContent = ({
  isSubmitted,
  isSubmitting,
  checkboxError,
  termsOfServiceRead,
  onCheckboxChange,
}: ToSAcceptanceModalProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Alert
        className={styles['tos-modal__alert']}
        title={
          <>
            {t('tosModal.alertTitle')}
            <Link href={TERMS_OF_SERVICE_URL} external target="_blank">
              {t('tosModal.termsOfService')}
            </Link>
          </>
        }
        type="info"
      >
        {t('tosModal.alertDescription')}
        <Link href={FAQ_URL} external target="_blank">
          {t('tosModal.here')}
        </Link>
        .
      </Alert>
      <FormField
        size="large"
        id="accept-terms-of-service"
        errorMessage={checkboxError}
        submitted={isSubmitted}
        submitting={isSubmitting}
      >
        <Checkbox
          id="accept-terms-of-service"
          name="accept-terms-of-service"
          error={!!checkboxError}
          checked={termsOfServiceRead}
          onChange={onCheckboxChange}
          label={
            <span>
              {t('tosModal.checkbox')}
              <Link
                href={TERMS_OF_SERVICE_URL}
                external
                className={styles['tos-modal__link']}
                target="_blank"
              >
                {t('tosModal.termsOfService')}
              </Link>
            </span>
          }
        />
      </FormField>
    </>
  )
}

export default ToSAcceptanceModalContent
