import { Formik, FormikErrors } from 'formik'
import React, { useState } from 'react'
import { Link } from 'yordex-ui-kit'
import { object } from 'yup'
import PublicLayout from 'components/public-layout/PublicLayout'
import { passwordValidationSchema } from 'utils/validation/user'
import NewPasswordForm from './new-password-form/NewPasswordForm'
import styles from './choose-new-password.module.scss'
import { Navigate, useParams } from 'react-router-dom'
import useResetPassword from './hooks/useChangePassword'
import { useRedirectIfAlreadyAuthorized } from 'hooks/useRedirectIfAlreadyAuthorized/useRedirectIfAlreadyAuthorized'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

export interface FormValues {
  newPassword: string
  confirmPassword: string
}

const validationSchema = object().shape({
  newPassword: passwordValidationSchema,
  confirmPassword: passwordValidationSchema,
})

const validate = (values: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {}

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'New password and confirmation do not match'
  }

  return errors
}

const ChooseNewPassword = () => {
  const { t } = useTranslation()
  useRedirectIfAlreadyAuthorized()
  const { email, passwordResetKey } = useParams<{
    email: string
    passwordResetKey: string
  }>()
  const [submitted, setSubmitted] = useState(false)
  const {
    handleChangePassword,
    changedPassword,
    errorMessage,
  } = useResetPassword(email!, passwordResetKey!)

  if (changedPassword) {
    return (
      <Navigate
        to={'/login'}
        state={{
          email: decodeURIComponent(email!),
          password: changedPassword,
        }}
      />
    )
  }

  return (
    <PublicLayout
      title={t('passwordReset.title')}
      subtitle={
        <>
          {t('passwordReset.subtitle')}
          <Link
            href="https://www.yordex.com/contact/"
            external
            className={styles['choose-new-password__link']}
          >
            {t('passwordReset.supportLink')}
          </Link>
        </>
      }
    >
      <Formik<FormValues>
        initialValues={{ newPassword: '', confirmPassword: '' }}
        onSubmit={handleChangePassword}
        validationSchema={validationSchema}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <NewPasswordForm
            submitting={isSubmitting}
            submitted={submitted}
            onSubmit={() => setSubmitted(true)}
            errorMessage={errorMessage}
          />
        )}
      </Formik>
    </PublicLayout>
  )
}

export default ChooseNewPassword
