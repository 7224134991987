import { useTranslation } from 'hooks/useTranslation/useTranslation'
import React, { useEffect } from 'react'
import { isEbury } from 'utils/ebury/isEbury'
import { Helmet } from 'react-helmet'

const PageMeta = () => {
  const { t } = useTranslation()
  const title = isEbury() ? t('title.ebury') : t('title.yordex')

  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement
    if (favicon) {
      if (isEbury()) {
        favicon.href = '/assets/images/ebury.ico'
      } else {
        favicon.href = '/assets/images/yordex.ico'
      }
    }
  }, [])

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

export default PageMeta
