import { useEffect } from 'react'
import initTranslations from '../../i18n'
import { useTranslation as usei18nTranslation } from 'react-i18next'
import { useGetLocationLanguage } from 'templates/login/hooks/useGetLocationLanguage'

const i18n = initTranslations()

export const useTranslation = () => {
  const { locationLanguage } = useGetLocationLanguage()
  useEffect(() => {
    const language = localStorage.getItem('language')
    if (language) {
      i18n?.changeLanguage(language?.toLowerCase())
    }
  }, [locationLanguage])

  return usei18nTranslation(undefined, { i18n })
}
