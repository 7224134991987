import { get, patch } from 'utils/fetch/fetchHelper'

type TermsOfService = {
  accepted: boolean
}

type User = {
  termsOfService?: TermsOfService
}

export const fetchUser = (userId: string) => {
  return get<User>(`/users/${userId}`)
}

export const updateTermsOfService = (
  userId: string,
  termsAccepted: boolean,
) => {
  return patch<Required<User>, void>(`/users/${userId}`, {
    termsOfService: { accepted: termsAccepted },
  })
}
