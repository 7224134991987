import { get } from 'utils/fetch/fetchHelper'
import { PagedResponse } from 'server/types/paged-response'

interface ScanResults {
  description: string
  invoiceAmountInCents: number
  invoiceCurrency: string
  invoiceDate: string
  invoiceNumber: string
  invoiceVatAmountInCents: number
  paymentDueDate: string
  sellerCompanyName: string
}

export interface TradersOrders {
  autoCreated: boolean
  autoMatched: boolean
  datamolinoDocumentId: string
  fileName: string
  id: string
  location: string
  matchedEventId: string
  matchedOrderId: string
  matchingOrders: Array<string>
  originalInvoiceType: string
  scanResults: ScanResults
  sellerId: string
  status: string // TODO type for status once it is used
  unknownEmails: Array<string>
  userIds: Array<string>
}

export interface TraderOrderFilters {
  userId?: string
  status?: string
  size?: number
}

export const fetchTraderOrdersForUser = ({
  userId = '',
  status = '',
  size = 100,
}: TraderOrderFilters) =>
  get<PagedResponse<TradersOrders>>(
    `/traderorders?userId=${userId}&status=${status}&size=${size}`,
  )
