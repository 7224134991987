import { useTranslation } from 'hooks/useTranslation/useTranslation'

type ModalActions = {
  onPrimaryButtonClick: () => void
  onSecondaryButtonClick: () => void
  primaryButtonText: string
  secondaryButtonText: string
}

export const useModalActions = (
  termsOfServiceDeclined: boolean,
  setFieldValue: (name: string, value: boolean) => void,
  submitForm: () => void,
): ModalActions => {
  const { t } = useTranslation()

  const onGoBack = () => {
    setFieldValue('termsOfServiceDeclined', false)
  }
  const onDecline = () => {
    setFieldValue('termsOfServiceDeclined', true)
  }

  if (termsOfServiceDeclined) {
    return {
      onPrimaryButtonClick: () => {
        submitForm()
      },
      onSecondaryButtonClick: onGoBack,
      primaryButtonText: t('tosModal.continueAndDecline'),
      secondaryButtonText: t('tosModal.goBack'),
    }
  } else {
    return {
      onPrimaryButtonClick: () => {
        submitForm()
      },
      onSecondaryButtonClick: onDecline,
      primaryButtonText: t('tosModal.accept'),
      secondaryButtonText: t('tosModal.decline'),
    }
  }
}
