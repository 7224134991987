import { get } from 'utils/fetch/fetchHelper'
import { transformSettings } from './settingsTransformer'
import {
  ApiSettings,
  ApiSettingsV2,
  parseUISettings,
  TraderSettings,
  UISettings,
} from 'yordex-ui-kit'

type SettingsResult = {
  settings: TraderSettings
  uiSettings?: UISettings
}

export const fetchSettings = async (
  traderId: string,
  userRoles: Array<string> = [],
): Promise<SettingsResult> => {
  const data = await get<ApiSettings>(`/settings?traderId=${traderId}`)

  const settings = transformSettings(data, userRoles)
  const rawSettings = data.uiSettingsV2
    ? (JSON.parse(data.uiSettingsV2) as ApiSettingsV2)
    : undefined
  const uiSettings =
    rawSettings && (await parseUISettings(rawSettings, userRoles))

  return {
    settings,
    uiSettings,
  }
}
