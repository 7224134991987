import { createContext, useContext } from 'react'
import { noop } from 'utils/noop'

export interface ErrorMessage {
  title: string
  description: string
}
export type ErrorSetter = (error: ErrorMessage | undefined) => void
export interface ErrorContextType {
  error?: ErrorMessage
  setError: ErrorSetter
}

export const ErrorContext = createContext<ErrorContextType>({
  setError: noop,
})

export const useErrorContext = () => {
  const context = useContext(ErrorContext)

  return context
}
