import 'react-app-polyfill/ie11'
import React from 'react'
import App from './App'
import { unregister } from './registerServiceWorker'
import './i18n'
import { createRoot } from 'react-dom/client'

const root = createRoot(document.getElementById('root')!)
root.render(<App />)
unregister()
