import React, { useCallback, useMemo } from 'react'
import { useField, Form, useFormikContext } from 'formik'
import {
  PrimaryButton,
  FormField,
  Alert,
  ApiSession,
  PhoneNumber,
  PhonePicker,
} from 'yordex-ui-kit'
import styles from './phone-number-form.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

interface Props {
  submitting: boolean
  submitted: boolean
  errorMessage?: string
  onSubmit: () => void
  session?: ApiSession
  ismobileNumberVerified?: boolean
}

export const PhoneNumberForm = ({
  submitting,
  submitted,
  errorMessage,
  onSubmit,
  ismobileNumberVerified,
}: Props) => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const [mobileNumberField, mobileNumberMeta] = useField('mobileNumber')
  const [mobileNumberCountryCodeField] = useField({
    name: 'mobileNumberCountryCode',
    required: true,
  })
  const [phoneCountryCodeField] = useField('phoneCountryCode')

  const value: PhoneNumber = useMemo(() => {
    return {
      number: mobileNumberField.value,
      countryCode: mobileNumberCountryCodeField.value,
      key: phoneCountryCodeField.value,
    }
  }, [mobileNumberField, mobileNumberCountryCodeField, phoneCountryCodeField])

  const handleOnChange = useCallback(
    (value: PhoneNumber) => {
      setFieldValue('mobileNumber', value.number)
      setFieldValue('mobileNumberCountryCode', value.countryCode)
      setFieldValue('phoneCountryCode', value.key)
    },
    [setFieldValue],
  )

  return (
    <Form className={styles['phone-number-form']} noValidate>
      {ismobileNumberVerified && (
        <Alert
          className={styles['phone-number-form__error-message']}
          type="info"
          title={t('logIn.verifyYourPhoneNumber')}
        >
          {t('logIn.verifyPhoneNumberInfo')}
        </Alert>
      )}
      {errorMessage && (
        <Alert
          className={styles['phone-number-form__error-message']}
          type="error"
        >
          {errorMessage}
        </Alert>
      )}
      <FormField
        id="mobileNumber"
        label={{
          name: t('logIn.mobilePhoneNumber'),
        }}
        errorMessage={mobileNumberMeta.error}
        submitting={submitting}
        submitted={submitted}
        required={true}
        className={styles['phone-number-form__container']}
      >
        <PhonePicker
          id="mobileNumber"
          name="mobileNumber"
          data-testid="verify-phone-input"
          value={value}
          onChange={handleOnChange}
          required={true}
          disabled={submitting}
          error={submitted && !!mobileNumberMeta.error}
          autoFocus={true}
        />
      </FormField>
      <PrimaryButton
        type="submit"
        data-testid="verify-number-button"
        large
        disabled={submitting}
        className={styles['phone-number-form__button']}
        onClick={onSubmit}
      >
        {t('logIn.verifyMyPhoneNumber')}
      </PrimaryButton>
    </Form>
  )
}
