import { Formik } from 'formik'
import React, { useState } from 'react'
import { Link } from 'yordex-ui-kit'
import PublicLayout from 'components/public-layout/PublicLayout'
import ResetPasswordForm from './reset-password-form/ResetPasswordForm'
import styles from './forgot-password.module.scss'
import useResetPassword from './hooks/useResetPassword'
import { object } from 'yup'
import { emailValidationSchema } from 'utils/validation/user'
import { useRedirectIfAlreadyAuthorized } from 'hooks/useRedirectIfAlreadyAuthorized/useRedirectIfAlreadyAuthorized'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

export interface FormValues {
  email: string
}

/* eslint-disable prettier/prettier */
const validationSchema = object().shape({
  email: emailValidationSchema,
})
/* eslint-enable prettier/prettier */

const CheckInboxMessage = ({ emailToCheck }: { emailToCheck: string }) => {
  const { t } = useTranslation()

  return (
    <PublicLayout
      title={t('forgotPassword.checkInboxTitle')}
      subtitleClassName={styles['forgot-password__subtitle']}
      subtitle={t('forgotPassword.checkInboxSubtitle', { emailToCheck })}
    >
      <p className={styles['forgot-password__paragraph']}>
        {t('forgotPassword.checkInboxRemember')}
        <Link href="/login" className={styles['forgot-password__link']}>
          {t('forgotPassword.checkInboxBackLink')}
        </Link>
      </p>
    </PublicLayout>
  )
}

const ForgotPassword = () => {
  const { t } = useTranslation()
  useRedirectIfAlreadyAuthorized()
  const [submitted, setSubmitted] = useState(false)
  const {
    errorMessage,
    handleResetPassword,
    wasPasswordReset,
    emailToCheck,
  } = useResetPassword()

  if (wasPasswordReset && emailToCheck) {
    return <CheckInboxMessage emailToCheck={emailToCheck} />
  }

  return (
    <PublicLayout
      title={t('forgotPassword.title')}
      subtitle={
        <>
          {t('forgotPassword.subtitle')}
          <Link href="/login" className={styles['forgot-password__link']}>
            {t('forgotPassword.logInLink')}
          </Link>
        </>
      }
    >
      <Formik<FormValues>
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleResetPassword}
      >
        {({ isSubmitting }) => (
          <ResetPasswordForm
            submitting={isSubmitting}
            submitted={submitted}
            onSubmit={() => setSubmitted(true)}
            errorMessage={errorMessage}
          />
        )}
      </Formik>
    </PublicLayout>
  )
}

export default ForgotPassword
