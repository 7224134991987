import { get } from 'utils/fetch/fetchHelper'

export interface TraderInformation {
  id: string
  name: string
  value: string
  traderSlug?: string
}

export const fetchTraderInformation = (traderId: string) =>
  get<TraderInformation>(`/traders/${traderId}`)
